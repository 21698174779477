.range-slider_wrapper{
	& > div{
		& > div {
			position: relative;
			& > div {
				&:focus-visible{
					outline: 0;
				}
			}
		}
	}
	.range-info{
		display: flex;
		justify-content: space-between;
		@include fontsize(16px);
		font-weight: $fw-bold;
		& > div{
			&:after{
				display: none;
			}
		}
	}
}