.seller-dialogue,
.user-dialogue {
  padding: 25px 0 30px;

  @media all and (min-width: 1200px) {
    padding-bottom: 62px;
  }

  .card {
    padding: 0;
  }

  .dialogue-wrap {
    display: flex;
    flex-direction: column;
    border: 0.25px solid $gray;
    @include radius(4px);
    height: 600px;

    @media all and (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      overflow: hidden;
    }

    @media all and (min-width: 1200px) {
      height: 750px;
      overflow: hidden;
    }
  }

  .dialogue-left {
    position: relative;
    width: 100%;

    @media all and (min-width: 768px) {
      width: 23%;
      overflow: hidden;
      height: 100%;
    }

    @media all and (min-width: 1200px) {
      width: 15%;
    }
  }

  .users-header {
    padding: 10px 10px;
    border: 0.25px solid $gray;

    @media all and (min-width: 768px) {
      border-top-left-radius: 4px;
      padding: 16px 10px;
    }

    button {
      color: $gray-5;
      background: transparent;
      outline: none;
      margin-right: 10px;
      display: inline-block;

      @media all and (min-width: 768px) {
        margin-right: 0;
        display: block;
      }

      &:first-child {
        @media all and (min-width: 1200px) {
          margin-bottom: 12px;
        }
      }
    }
  }

  .users-wrap {
    height: 100%;
    height: calc(100% - 72px);
    overflow: auto;
  }

  .users-list {
    width: 100%;
    transition: 0.3s;
    background: $white;

    @media all and (min-width: 768px) {
      box-shadow: inset -1px 0px 0px #f0f0f0;
      padding-right: 1px;
      border-width: 0 0 0 0.25px;
      border-style: solid;
      border-color: $gray;
      border-bottom-left-radius: 4px;
    }

    &.active {
      right: 0;
    }

    li {
      @media all and (min-width: 992px) {
        margin-bottom: 5px;
      }
    }

    a {
      line-height: 1.5;
      color: $gray-5;
      display: block;
      border-right: 3px solid transparent;
      padding: 8px;

      @media all and (min-width: 992px) {
        padding: 9px 16px;
      }

      &.active {
        color: $blue;
        background: $blue-light;
        border-color: $blue;
        font-weight: $fw-bold;
      }
    }
  }

  .scroll-users {
    @media all and (min-width: 768px) {
      // height: calc(100% - 56px) !important;
    }
  }

  .dialogue-right {
    width: 100%;
    position: relative;
    height: calc(100% - 41px);

    @media all and (min-width: 768px) {
      width: 77%;
      height: 100%;
    }

    @media all and (min-width: 1200px) {
      width: 85%;
    }

    .user-header {
      padding: 10px 70px;
      background: $gray-5-transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.25px solid $gray;
      border-right: 0;
      z-index: 1;
      position: relative;

      @media all and (min-width: 768px) {
        padding: 16px 80px;
        border-top-right-radius: 4px;
      }

      @media all and (min-width: 992px) {
        padding-left: 100px;
        padding-right: 100px;
      }

      @media all and (min-width: 1200px) {
        padding: 15px 120px 20px;
      }

      .back-link {
        padding-left: 15px;
        color: $gray-2;
        position: relative;

        @media all and (min-width: 768px) {
          @include fontsize(18px);
        }

        @media all and (min-width: 1200px) {
          @include fontsize(20px);
          padding-left: 23px;
        }

        &:before {
          content: "";
          width: 6px;
          height: 14px;
          background: url(../../images/icons/icon-left-2.svg) no-repeat;
          @include absolute(4px, auto, auto, 0);

          @media all and (min-width: 768px) {
            top: 6px;
          }

          @media all and (min-width: 1200px) {
            width: 9px;
          }
        }
      }

      .name-wrap {
        padding: 0 10px;

        .name {
          font-weight: $fw-bold;
          display: block;
          text-align: center;

          @media all and (min-width: 768px) {
            @include fontsize(18px);
          }

          @media all and (min-width: 992px) {
            @include fontsize(20px);
          }

          @media all and (min-width: 1200px) {
            @include fontsize(24px);
          }
        }

        .status {
          display: block;
          text-align: center;
          @include fontsize(12px);
          color: $gray-2;

          @media all and (min-width: 768px) {
            @include fontsize(14px);
          }

          @media all and (min-width: 1024px) {
            @include fontsize(16px);
          }
        }
      }

      .back-wrap {
        @include absolute(10px, auto, auto, 10px);
        z-index: 1;

        @media all and (min-width: 768px) {
          top: 17px;
        }

        @media all and (min-width: 992px) {
          left: 30px;
        }

        @media all and (min-width: 1200px) {
          top: 20px;
        }
      }

      .more-wrap {
        min-width: 50px;
        @include absolute(6px, 10px);
        z-index: 1;
        margin-top: 0px;

        @media all and (min-width: 768px) {
          min-width: 54px;
          top: 12px;
        }

        @media all and (min-width: 992px) {
          right: 30px;
        }

        @media all and (min-width: 1200px) {
          min-width: 66px;
          top: 16px;
          margin-right: 0;
        }

        button {
          display: block;
          background: transparent;
          outline: 0;
          border: 0;
          margin-left: auto;

          .icon-dots {
            display: block;
            width: 32px;
            height: 32px;
            background: url(../../images/icons/icon-dots.svg) no-repeat;
          }
        }
      }
    }

    .scroll-msg {
      height: calc(100% - 165px) !important;
      @media all and (min-width: 768px) {
        height: calc(100% - 138px) !important;
      }
      @media all and (min-width: 992px) {
        height: calc(100% - 126px) !important;
      }
      @media all and (min-width: 1200px) {
        height: calc(100% - 147px) !important;
      }
      & > div{
        padding: 0 10px;
        @media all and (min-width: 768px){
          padding: 0 30px;
        }
        @media all and (min-width: 1200px){}
        padding: 0 28px;
      }
    }

    .msg-box {
      padding: 20px 0;
      height: calc(100% - 41px);
      @media all and (min-width: 768px) {
        padding: 20px 0;
      }

      @media all and (min-width: 1200px) {
        padding: 16px 0;
      }
      .top-wrap {
        margin-bottom: 10px;
        padding: 0 10px;
        @media all and (min-width: 768px){
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 30px;
          min-height: 33px;
        }
        @media all and (min-width: 1200px) {
          margin-bottom: 20px;
          padding: 0 28px;
        }
        .link-wrap {
          direction: rtl;
          @media all and (min-width: 768px) {
            display: inline-block;
          }
          .page-link {
            color: $blue;
            border: 0.5px solid $gray;
            box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
            @include radius(2px);
            background: $white;
            @include fontsize(16px);
            display: block;
            margin-top: 10px;
            white-space: nowrap;
            margin: 0 auto;
            @media all and (min-width: 768px) {
              padding: 5px 18px 7px;
              margin: 0;
            }

            &:before {
              display: inline-block;
              content: "";
              background: url(../../images/icons/icon-blue-arrow.svg) no-repeat;
              width: 9px;
              height: 14px;
              margin-right: 0;
              margin-left: 10px;
              margin-bottom: -1px;
            }
          }
        }
      }

      .msg-tags {
        margin-bottom: 10px;
        @media all and (min-width: 768px){
          margin-bottom: 0;
        }
        @media all and (min-width: 992px) {
          display: flex;
          justify-content: flex-start;
        }
        @media all and (min-width: 768px){
          width: calc(100% - 245px);
        }

        .tag-wrap {
          text-align: center;
          @include fontsize(13px);
          color: $gray-2;
          @media all and (min-width: 768px){
            text-align: left;
          }
          @media all and (min-width: 992px) {
            margin: 0 6px;
            position: relative;

            &:after {
              content: "";
              width: 1px;
              height: 12px;
              background: $blue;
              @include absolute(2px, -6px);
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }

      .msg-wrap {
        @media all and (min-width: 768px) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 10px;
        }

        @media all and (min-width: 1200px) {
          margin-bottom: 30px;
        }

        &.sender {
          direction: rtl;

          .page-link {
            &:before {
              margin-right: 0;
              margin-left: 10px;
              margin-bottom: -1px;
            }
          }

          p {
            padding-right: 0;

            @media all and (min-width: 1200px) {
              padding-left: 40px;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .user-img {
          width: 72px;
          height: 72px;
          margin-bottom: 20px;
          border: 1px solid $gray-4;
          @include radius(50%);
          text-transform: capitalize;
          @include fontsize(24px);
          font-weight: $fw-bold;
          color: $blue;

          &.no-img {
            background: $gray-9;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .msg-details {
          @media all and (min-width: 768px) {
            width: calc(100% - 87px);
            padding-top: 4px;
          }
          .image-wrapper{
            width: calc(100% - 30px);
            height: 320px;
            @media all and (min-width: 992px){
              width: 300px;
              height: 250px;
            }
            @media all and (min-width: 992px){
              width: 400px;
              height: 250px;
            }
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
            }
          }
        }

        .user-name {
          font-weight: $fw-semibold;
          margin-bottom: 10px;

          @media all and (min-width: 768px) {
            @include fontsize(18px);
          }

          @media all and (min-width: 1200px) {
            @include fontsize(20px);
            margin-bottom: 4px;
          }
        }

        .link-wrap {
          .page-link {
            color: $blue;
            border: 0.5px solid $gray;
            box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
            @include radius(2px);
            background: $white;
            @include fontsize(16px);
            display: inline-block;
            margin-top: 10px;

            @media all and (min-width: 768px) {
              padding: 5px 18px 7px;
              margin-top: 0;
            }

            &:before {
              display: inline-block;
              content: "";
              background: url(../../images/icons/icon-blue-arrow.svg) no-repeat;
              width: 9px;
              height: 14px;
              margin-right: 10px;
            }
          }
        }

        .greet,
        p {
          color: $gray-5;
          line-height: 1.5;
          margin-bottom: 16px;
          letter-spacing: 0.1px;

          @media all and (min-width: 1200px) {
            padding-right: 40px;
          }
        }
      }
    }

    .msg-input {
      @include absolute(auto, 0, 0, 0);
      z-index: 1;
      background: rgb(253, 253, 253);
      padding: 10px 5px;
      border-width: 0.25px;
      border-style: solid;
      border-color: $gray;
      border-bottom-right-radius: 4px;
      border-right: 0;

      @media all and (min-width: 992px) {
        padding: 10px 26px;
        border-bottom: 0;
      }

      .form-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .clip-wrap {
        @media all and (min-width: 1200px) {
          margin-left: 20px;
        }

        button {
          background: transparent;
          outline: none;
          border: 0;
          display: block;

          .icon-clip {
            background: url(../../images/icons/icon-clip.svg) no-repeat;
            background-size: cover;
            display: block;
            width: 25px;
            height: 25px;

            @media all and (min-width: 768px) {
              width: 32px;
              height: 32px;
            }
          }
        }
      }

      .send-wrap {
        @media all and (min-width: 1200px) {
          margin-left: 20px;
        }

        button {
          background: transparent;
          outline: none;
          border: 0;
          display: block;

          .icon-send {
            background: url(../../images/icons/icon-send.svg) no-repeat;
            background-size: cover;
            display: block;
            width: 25px;
            height: 25px;

            @media all and (min-width: 768px) {
              width: 32px;
              height: 32px;
            }
          }
        }
      }

      .input-wrap {
        width: calc(100% - 80px);

        @media all and (min-width: 992px) {
          width: calc(100% - 185px);
          max-width: 780px;
        }

        textarea {
          width: 100%;
          resize: none;
          outline: none;
          padding: 5px;
          border: 1px solid $gray;
          color: $gray-2;
          background: $white;
          @include radius(2px);
          @include fontsize(16px);
          letter-spacing: 0.1px;
          display: block;

          @media all and (min-width: 1200px) {
            padding: 10px 14px;
            height: 65px;
          }
        }
      }
    }
  }

  .dialogue-left {
    position: relative;

    .users-header {
      background: $gray-5-transparent;

      @media all and (min-width: 768px) {
        height: 56px;
      }

      @media all and (min-width: 992px) {
        height: 58px;
      }

      @media all and (min-width: 1200px) {
        height: 66px;
      }
    }

    .users-wrap {
      border-right: 1px solid $gray;
      width: 100%;
      @include absolute(41px, calc(100% + 20px));
      z-index: 2;
      height: 400px;

      @media all and (min-width: 768px) {
        position: static;
        height: calc(100% - 66px);
      }

      &.active {
        right: 0;
      }

      // @include absolute(0, 100)
    }
  }
}