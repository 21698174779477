.forgot-password{
	padding: 30px 0;
	@media all and (min-width: 1200px){
		padding: 56px 0 96px;
	}
	.container{
		max-width: 600px;
		@media all and (min-width: 1300px) and (max-width: 1400px){
			max-width: 609px;
		}
	}
	.card{
		margin-bottom: 16px;
		@media all and (min-width: 768px){
			padding: 30px 50px;
		}
		@media all and (min-width: 1300px) and (max-width: 1400px){
			padding: 30px 95px;
		}
		@media all and (min-width: 1440px){
			padding: 56px 100px 39px;
		}
		&:nth-child(2){
			@media all and (min-width: 1440px){
				padding: 33px 167px 35px;
			}
		}
	}
	h2{
		text-align: center;
		margin-bottom: 18px;
	}
	.reset-info{
		color: $gray-5;
		text-align: center;
		margin-bottom: 20px;
		@media all and (min-width: 1200px){
			margin-bottom: 46px;
		}
	}
	.input-block{
		margin-bottom: 30px;
		position: relative;
		.error{
			padding: 0;
		}
		.input-wrap{
			position: relative;
		}
		.toggle-wrap{
			@include absolute(50%, 12px);
			z-index: 1;
			transform: translate(0, -50%);
			button{
				display: block;
			}
			.icon-eye{
				display: block;
				width: 16px;
				height: 16px;
				background: url(../../images/icons/icon-eye-2.svg) no-repeat;
			}
		}
	}
	label{
		color: $gray-3;
		margin-bottom: 5px;
		@media all and (min-width: 1200px){
			margin-bottom: 11px;
		}
	}
	.form-check-label{
		letter-spacing: 0.2px;
	}
	.btn-wrap{
		margin: 9px 0 10px;
		@media all and (min-width: 1200px){
			margin-bottom: 33px;
		}
	}
	.btn{
		@include fontsize(16px);
	}
	
	.login-link{
		color: $gray-3;
		margin-top: 0;
		padding-left: 22px;
		position: relative;
		&:before{
			content: '';
			background: url(../../images/icons/icon-Left.svg) no-repeat;
			width: 12px;
			height: 12px;
			@include absolute(5px, auto, auto, 0);
		}
	}
	&.success{
		.card{
			@media all and (min-width: 1440px){
				padding: 60px 100px 27px;
			}
			&:nth-child(2){
				@media all and (min-width: 1440px){
					padding: 33px 100px 35px;
				}
			}
		}
		.wrap-icon{
			width: 40px;
			height: 40px;
			margin: 0 auto 25px;
			@media all and (min-width: 768px){
				width: 70px;
				height: 70px;
			}
			.icon-success{
				width: 100%;
				height: 100%;
				background: url(../../images/icons/icon-success.svg) no-repeat;
				background-size: cover;
			}
		}
		h2{
			margin-bottom: 11px;
		}
		.reset-info{
			line-height: 1.5;
			@media all and (min-width: 1200px){
				margin-bottom: 29px;
			}
		}
	}
	&.check-mail{
		.card{
			@media all and (min-width: 1440px){
				padding: 56px 100px 92px;
			}
			&:nth-child(2) {
				@media all and (min-width: 1440px){
					padding: 33px 167px 35px;
				}
			}
		}
		h2{
			margin-bottom: 15px;
		}
		.reset-info{
			margin-bottom: 16px;
		}
		.mail-info{
			color: $gray-3;
			font-weight: $fw-semibold;
			display: block;
			margin-top: 5px;
		}
		.btn-wrap{
			@media all and (min-width: 992px){
				margin-bottom: 40px;
			}
			@media all and (min-width: 1200px){
				margin-bottom: 63px;
			}
		}
		.resend-wrap{
			text-align: center;
			.resend-link{
				color: $blue;
				text-decoration: underline;
				font-weight: $fw-semibold;
				margin-top: 5px;
				@media all and (min-width: 768px){
					margin: 0 0 0 24px;
				}
			}
		}
	}
}