
  
  #popup{
    position:absolute;
    width:600px;
    height:180px;
    background:#fff;
    left:50%;
    top:50%;
    border-radius:5px;
    padding:60px 0;
    margin-left:-320px; /* width/2 + padding-left */
    margin-top:-150px; /* height/2 + padding-top */
    text-align:center;
    box-shadow:0 0 10px 0 #000;
  }
  
  #close{
    position:absolute;
    background:black;
    color:white;
    right:-15px;
    top:-15px;
    border-radius:50%;
    width:30px;
    height:30px;
    line-height:30px;
    text-align:center;
    font-size:8px;
    font-weight:bold;
    font-family:'Arial Black', Arial, sans-serif;
    cursor:pointer;
    box-shadow:0 0 10px 0 #000;
  }