.your-orders {
	padding: 21px 0 60px;
	min-height: 90vh;
	@media all and (min-width: 1200px) {
	  padding-bottom: 74px;
	}
	.nav-tabs .nav-link {
	  @include fontsize(16px);
	}
	.order-block {
	  @media all and (min-width: 768px) {
		padding-top: 7px;
	  }
	  .order-wrap {
		background: $white;
		box-shadow: 2px 4px 8px $gray-6-transparent;
		@include radius(4px);
		overflow: hidden;
		padding: 15px;
		margin-bottom: 8px;
		@media all and (min-width: 992px) {
		  padding: 0;
		  display: flex;
		  flex-wrap: wrap;
		}
		.order-img {
		  width: 100px;
		  height: 100px;
		  margin-bottom: 10px;
		  @media all and (min-width: 992px) {
			width: 208px;
			height: 208px;
			margin-bottom: 0;
		  }
		  img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		  }
		}
		.order-info {
		  @media all and (min-width: 992px) {
			width: calc(100% - 208px);
			padding: 16px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		  }
		  @media all and (min-width: 1200px) {
			padding: 16px 16px 9px 31px;
		  }
		  .order-left {
			margin-bottom: 30px;
			@media all and (min-width: 992px) {
			  width: 60%;
			  margin-bottom: 0;
			}
			&.past{
				@media all and (min-width: 1200px) {
					width: 65%;
				  }
			}
		  }
		}
		.order-name {
		  margin-bottom: 2px;
		  a {
			color: $gray-3;
			font-weight: $fw-semibold;
			@media all and (min-width: 768px) {
			  @include fontsize(18px);
			}
			@media all and (min-width: 992px) {
			  @include fontsize(20px);
			}
		  }
		}
		.order-provider {
		  font-weight: $fw-bold;
		  color: $gray-2;
		  margin-bottom: 10px;
		  @media all and (min-width: 768px) {
			margin-bottom: 20px;
		  }
		}
		.order-detail {
		  @media all and (min-width: 768px) {
			display: flex;
			flex-wrap: wrap;
		  }
		  & > div {
			margin-bottom: 10px;
			@media all and (min-width: 768px) {
			  width: 42.5%;
			}
		  }
		  &.past{
			&>div{
				@media all and (min-width: 768px) {
					width: 31.5%;
				  }
			}
		  }
		}
		.order-heading {
		  color: $gray-2;
		  @include fontsize(13px);
		  @media all and (min-width: 768px) {
			margin-bottom: 3px;
		  }
		}
		.order-value {
		  color: $gray-5;
		  font-weight: $fw-bold;
		}
		.order-cta {
		  .wishlist-wrap {
			margin-bottom: 10px;
			@media all and (min-width: 992px) {
			  display: flex;
			  justify-content: flex-end;
			  padding: 11px 2px 0 0;
			  margin-bottom: 35px;
			}
			button {
			  background: transparent;
			  outline: 0;
			  color: $blue;
			  padding: 0;
			  font-weight: $fw-bold;
			  &:before {
				content: "";
				background: url(../../images/icons/icon-heart.svg) no-repeat;
				width: 16px;
				height: 14px;
				background-size: cover;
				display: inline-block;
				margin-right: 7px;
			  }
			  &.heart-filled {
				&::before {
				  background: url(../../images/icons/icon-heart-filled.svg)
					no-repeat;
				  background-size: cover;
				}
			  }
			}
		  }
		  .btns-wrap {
			@media all and (min-width: 992px) {
			  display: flex;
			  flex-direction: column;
			  align-items: flex-end;
			}
			& > div {
			  margin-bottom: 10px;
			  @media all and (min-width: 992px) {
				margin-bottom: 6px;
			  }
			  &:last-child {
				margin-bottom: 0;
			  }
			}
			.order-problem {
			  position: relative;
			  display: flex;
			  align-items: center;
			  @media all and (min-width: 992px) {
				margin-left: 110px;
			  }
			  .btn,
			  .secondary-btn {
				background: $white;
				color: $red-2;
				border-color: $red-2;
				&:hover {
				  background: $white;
				  color: $red-2;
				  border-color: $red-2;
				  opacity: 0.8;
				}
			  }
			  .open-dispute {
				@include fontsize(13px);
				color: $gray-2;
				@media all and (min-width: 992px) {
				  text-align: right;
				  @include absolute(5px, calc(100% + 7px));
				  z-index: 1;
				  width: 120px;
				}
				strong {
				  color: $gray-5;
				}
			  }
			}
			.secondary-btn,
			.btn {
			  min-width: 164px;
			  padding: 7px;
			  @include fontsize(16px);
			}
		  }
		}
	  }
	  .pagination-wrap {
		margin-top: 25px;
		.pagination {
		  .page-item {
			margin: 0 4.5px;
		  }
		}
	  }
	}
  }
  
  .rdt_TableHeadRow * {
    overflow: inherit !important;
    text-overflow: inherit !important;
}


.tabs-wrapper{
	position: relative;
	>a{
		margin-bottom: 15px;
		@media screen and (min-width: 768px) {
			position: absolute;
			right: 0;
			margin-bottom: 0;
			top: -12px;
		}
	}
}