.advertiser-dashboard{
	padding: 20px 0 60px;
	@media all and (min-width: 1200px){
		padding-bottom: 90px;
	}
	h1{
		margin-bottom: 20px;
		@media all and (min-width: 768px){
			margin-bottom: 30px;
		}
		@media all and (min-width: 992px){
			margin-bottom: 40px;
		}
	}
	.profile-card{
		display: block;
		padding: 15px;
		margin-bottom: 30px;
		@media all and (min-width: 768px){
			position: relative;
			padding: 30px 30px 50px;
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
		@media all and (min-width: 1200px){
			margin-bottom: 41px;
		}
		.profile-info{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 30px;
			@media all and (min-width: 768px){
				order: 2;
				padding: 20px 20px 0;
				margin-bottom: 0;
			}
			@media all and (min-width: 992px){
				padding-top: 30px;
			}
			.profile-img{
				position: relative;
				width: 140px;
				height: 120px;
				margin-right: -20px;
				margin-bottom: 20px;
				margin: 0 -20px 16px 0;
				.img-wrap{
					width: 120px;
					height: 100%;
					overflow: hidden;
					@include radius(50%);
					position: relative;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						display: block;
					}
					&:after{
						display: block;
						content: '';
						width: 100%;
						height: 100%;
						border: 1px solid $gray-9;
						@include absolute(0, 0, 0, 0);
						@include radius(50%);
						z-index: 1;
					}
				}
				.file-input{
					@include absolute(0, 0, 0, 0);
					z-index: 5;
					width: 100%;
					height: 100%;
					cursor: pointer !important;
					form{
						display: block;
						width: 100%;
						height: 100%;
					}
					input{
						display: block;
						width: 100%;
						height: 100%;
						opacity: 0;
						cursor: pointer;
						font-size: 0;
					}
				}
				.btn-wrap{
					overflow: hidden;
					width: 40px;
					height: 40px;
					display: block;
					@include radius(50%);
					border: 1px solid $gray-9;
					@include absolute(53px, 1px);
					z-index: 2;
					background: $white;
					.icon-plus{
						display: block;
						width: 100%;
						height: 100%;
						background: url(../../images/icons/icon-plus-3.svg) no-repeat;
						background-position: center;
					}
				}
			}
			.profile-name{
				text-align: center;
				font-weight: $fw-bold;
				color: $gray-3;
				margin-bottom: 3px;
			}
			.profile-mail{
				text-align: center;
				a{
					font-weight: $fw-medium;
					color: $gray-2;
				}
			}
			
		}
		.btn-wrap{
			margin-bottom: 10px;
			order: 3;
			@media all and (min-width: 768px){
				@include absolute(30px, 30px);
			}
			a{
				width: 100%;
				box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
				padding: 5px 20px;
				font-weight: $fw-semibold;
				@media all and (min-width: 768px){
					min-width: 130px;
				}
				@media all and (min-width: 992px){
					min-width: 240px;
				}
			}
			.secondary-btn{
				border-color: transparent;
				color: $red-2;
				&:hover{
					background: $white;
				}
			}
			&.delete{
				@media all and (min-width: 768px){
					order: 1;
				}
			}
		}
	}
	.banner-header{
		margin-bottom: 12px;
		@media all and (min-width: 768px){
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: -26px;
			// position: relative;
			// z-index: 1;
		}
		h3{
			margin-bottom: 20px;
			@media all and (min-width: 768px){
				margin-bottom: 0;
				padding-top: 16px;
				position: relative;
				z-index: 1;
			}
		}
		.tab-btns{
			display: flex;
			white-space: nowrap;
			width: 100%;
			overflow: auto;
			margin-bottom: 20px;
			@media all and (min-width: 768px){
				width: calc(100% - 480px);
				margin-bottom: 0;
				padding-top: 16px;
			}
			button{
				padding: 0;
				background: transparent;
				outline: 0;
				color: $gray-5;
				border-bottom: 2px solid transparent;
				padding-bottom: 3px;
				margin-right: 10px;
				@media all and (min-width: 768px){
					@include fontsize(18px)
				}
				@media all and (min-width: 992px){
					@include fontsize(20px);
				}
				@media all and (min-width: 1200px){
					@include fontsize(24px);
					margin-right: 31px;
					padding-bottom: 12px;
				}
				&.active{
					font-weight: $fw-bold;
					color: $blue;
					border-color: $blue;
				}
			}
		}
		.btn-wrap{
			@media all and (min-width: 768px){
				margin-top: auto;
				position: relative;
				z-index: 1;
			}
			.btn{
				padding: 4px 36px;
				font-weight: $fw-semibold;
				min-width: 240px;
			}
		}
	}
	.tab-content{
		.card{
			padding: 15px;
			@media all and (min-width: 768px){
				padding: 30px;
			}
			@media all and (min-width: 1200px){
				padding: 40px 59px 55px;
				flex-direction: row;
				justify-content: space-between;
				flex-wrap: wrap;
			}
		}
		.banner-wrap{
			position: relative;
			padding: 15px;
			color: $gray-3;
			background: $gray-11;
			margin-bottom: 25px;
			min-height: 260px;
			@media all and (min-width: 768px){
				padding: 17px 30px 52px;
				background-image:  url(../../images/banner-bg.png);
				background-repeat: no-repeat;
				background-position: 100% 0px;
			}
			@media all and (min-width: 1200px){
				width: calc(50% - 15px);
			}
			.more-btn{
				@include absolute(5px, 	15px);
				z-index: 1;
				@media all and (min-width: 768px){
					right: 20px;
				}
				button{
					background: transparent;
					outline: 0;
					border: 0;
					width: 30px;
					height: 30px;
					.icon-ellipsis{
						width: 100%;
						height: 100%;
						display: block;
						background: url(../../images/icons/icon-ellipsis.svg) no-repeat center;
					}
				}
			}
			.content-wrap{
				@media all and (min-width: 768px){
					width: 62%;
				}
			}
			.form-check{
				margin-bottom: 14px;
				.form-check-input{
					border-color: $green;
					&:checked{
						background-color: $green;
					}
				}
			}
			.date-wrap{
				@include fontsize(14px);
				color: $gray-3;
				margin-bottom: 9px;
			}
			h3{
				font-weight: $fw-black2;
				margin-bottom: 7px;
				@media all and (min-width: 1200px){
					@include fontsize(28px);
				}
			}
			p{
				font-family: $third-fontfamily;
				margin-bottom: 20px;
				@media all and (min-width: 768px){
					@include fontsize(18.83px);
				}
			}
			.btn-wrap{
				.btn{
					@include radius(0px);
					padding: 5px 20px;
					font-weight: $fw-semibold;
					@media all and (min-width: 768px){
						min-width: 253px;
						@include fontsize(18px);
					}
				}
			}
		}
	}
	&.edit{
		.btn-wrap{
			display: flex;
			justify-content: center;
			margin-top: 30px;
			@media all and (min-width: 768px){
				margin-top: 37px;
				justify-content: flex-end;
			}
			.btn{
				padding: 7px 43px;
			}
			.btn, .secondary-btn{
				@media all and (min-width: 992px){
					padding: 5px 20px 6px;
					min-width: 240px;
				}
				@media all and (min-width: 1200px){
					padding: 4px 20px;
				}
			}
			.btn{
				@media all and (min-width: 1200px){
					padding: 4px 20px;
				}
				@media all and (min-width: 1300px) and (max-width: 1400px){
					padding: 4px 20px;
				}
			}
			.secondary-btn{
				margin-right: 24px;
				@media all and (min-width: 1300px) and (max-width: 1400px){
					padding: 4px 20px;
				}
			}
		}
		
		.toggle-wrap{
			@include absolute(50%, 12px);
			z-index: 1;
			transform: translate(0, -50%);
			button{
				display: block;
			}
			.icon-eye{
				display: block;
				width: 16px;
				height: 16px;
				background: url(../../images/icons/icon-eye-2.svg) no-repeat;
			}
			button{
				&.show{
					.icon-eye{
						background: url(../../images/icons/icon-eye-4.svg) no-repeat;
					}
				}
			}
		}
		.password-hint{
			color: $gray-2;
		}
	}
	.nav.nav-tabs{
		border: 0;
		@media all and (min-width: 768px){
			width: auto;
			max-width: 100%;
			margin: 0 140px;
		}
		@media all and (min-width: 1200px){
			margin: 0 180px;
		}
	}
	.menu-wrap{
		margin: 0 !important;
		.menu{
			button{
				width: 100% !important;
			}
		}
	}
}