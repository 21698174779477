.banner {
	padding: 30px 0;
	overflow: hidden;

	@media all and (min-width: 992px) {
		padding: 39px 0;
	}

	.banner-wrap {
		box-shadow: 2px 4px 8px $gray-6-transparent;
		background: $white;
		// display: flex;
		// flex-wrap: wrap;
		@include radius(2px);
		overflow: hidden;

		// .banner-right{
		// 	width: 100%;
		// 	order: 1;
		// 	margin-bottom: 30px;
		// 	@media all and (min-width: 768px){
		// 		width: 48%;
		// 		order: 2;
		// 		margin-bottom: 0;
		// 	}
		// 	@media all and (min-width: 992px){
		// 		width: 56%;
		// 	}
		// }
		// .banner-left{
		// 	width: 100%;
		// 	order: 2;
		// 	@media all and (min-width: 768px){
		// 		width: 48%;
		// 		order: 1;
		// 	}
		// 	@media all and (min-width: 992px){
		// 		width: 39%;
		// 	}
		// }
		// h1{
		// 	margin-bottom: 10px;
		// 	@media all and (min-width: 992px){
		// 		margin-bottom: 22px;
		// 	}
		// }
		// p{
		// 	line-height: 1.5;
		// 	color: $gray-5;
		// 	margin-bottom: 15px;
		// 	letter-spacing: 0.022px;
		// 	@media all and (min-width: 992px){
		// 		margin-bottom: 65px;
		// 	}
		// }
		.img-wrap {
			height: 378px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
			}
		}

		.btn-wrap {
			.btn {
				@include fontsize(16px);

				@media all and (min-width: 768px) {
					padding: 7px 86px;
				}
			}
		}
	}

	.slick-dots {
		display: flex !important;
		justify-content: center;
		margin-top: 10px;

		@media all and (min-width: 768px) {
			margin-top: 14px;
		}

		li {
			opacity: 0.3;
			transition: 0.3s;
			margin: 0 3px;

			&.slick-active {
				opacity: 1;

				button {
					width: 24px;
				}
			}

			button {
				font-size: 0;
				width: 16px;
				height: 3px;
				background: $gray-3;
				transition: 0.3s;
				outline: 0;
			}
		}
	}
}

// Treding Services Section Start
.trending-services {
	padding-bottom: 34px;

	h2 {
		text-align: center;
		margin-bottom: 0;

		@media all and (min-width: 1200px) {
			// margin-bottom: 30px;
		}

		@media all and (min-width: 1440px) {
			// margin-bottom: 38px;
		}
	}

	.slick-slider {
		margin: 0;

		@media all and (min-width: 567px) {
			margin: 0 -10px;
		}

		@media all and (min-width: 1200px) {
			margin: 0 -14px;
		}

		.slick-list {
			overflow: hidden !important;
		}

		.card {
			padding: 0px;
			overflow: hidden;
			height: 100%;
		}

		a {
			display: block;
		}

		.service-img {
			width: 100%;
			height: 266px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
			}
		}

		.service-name {
			padding: 20px;

			@media all and (min-width: 1200px) {
				padding: 30px 20px;
			}
		}

		h3 {
			text-align: center;
		}

		.slick-arrow {
			top: calc(50% - 7px);
			bottom: auto;

			@media all and (min-width: 992px) {
				top: calc(50% - 12px);
			}

			@media all and (min-width: 1200px) {
				width: 40px;
				height: 40px;
				top: calc(50% - 20px);
			}

			&.slick-prev {
				left: -17px;

				@media all and (min-width: 567px) {
					left: -7px;
				}

				@media all and (min-width: 1200px) {
					left: -30px;
				}

				@media all and (min-width: 1440px) {
					left: -48px;
				}
			}

			&.slick-next {
				right: -17px;

				@media all and (min-width: 567px) {
					right: -7px;
				}

				@media all and (min-width: 1200px) {
					right: -30px;
				}

				@media all and (min-width: 1440px) {
					right: -48px;
				}
			}
		}

		.slick-slide {
			padding: 0;

			@media all and (min-width: 567px) {
				padding: 0 10px;
			}

			@media all and (min-width: 1200px) {
				padding: 0 14px;
			}
		}
	}
}

// Recommended Services
.recommended-services {
	h2 {
		text-align: center;
		margin-bottom: 20px;

		@media all and (min-width: 1200px) {
			margin-bottom: 30px;
		}

		@media all and (min-width: 1440px) {
			margin-bottom: 38px;
		}
	}

	.slick-slider {
		margin: 0;

		@media all and (min-width: 567px) {
			margin: 0 -10px;
		}

		@media all and (min-width: 1200px) {
			margin: 0 -14px;
		}

		.slick-track {
			display: flex;
		}

		.slick-slide {
			height: inherit;

			&>div {
				height: 100%;

				.slide {
					height: 100%;
				}
			}
		}

		.slick-list {
			overflow: hidden !important;
		}

		.slick-arrow {
			top: calc(50% - 7px);
			bottom: auto;

			@media all and (min-width: 992px) {
				top: calc(50% - 12px);
			}

			@media all and (min-width: 1200px) {
				width: 40px;
				height: 40px;
				top: calc(50% - 20px);
			}

			&.slick-prev {
				left: -17px;

				@media all and (min-width: 567px) {
					left: -7px;
				}

				@media all and (min-width: 1200px) {
					left: -30px;
				}

				@media all and (min-width: 1440px) {
					left: -48px;
				}
			}

			&.slick-next {
				right: -17px;

				@media all and (min-width: 567px) {
					right: -7px;
				}

				@media all and (min-width: 1200px) {
					right: -30px;
				}

				@media all and (min-width: 1440px) {
					right: -48px;
				}
			}
		}

		.slick-slide {
			padding: 0;

			@media all and (min-width: 567px) {
				padding: 0 10px;
			}

			@media all and (min-width: 1200px) {
				padding: 0 14px;
			}
		}
	}

	.recommended-service_wrapper {
		height: 100%;

		.service-block {
			background: $white;
			box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
			border-radius: 4px;
			transition: all 0.5s;
			display: block;
			overflow: hidden;
			height: 100%;

			&:hover {
				opacity: 1;
				box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.3);
			}

			.service-image {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				height: 165px;

				@media all and (min-width: 1200px) {
					height: 160px;
				}

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.service-card {
				padding: 17px 16px 18px 15px;
				height: calc(100% - 165px);
				display: flex;
				flex-direction: column;

				@media all and (min-width: 1200px) {
					height: calc(100% - 160px);
				}

				.service-name {
					@include fontsize(20px);
					font-weight: $fw-semibold;
					color: $gray-3;
					margin-bottom: 10px;
				}

				.provider-info {
					@include fontsize(16px);
					font-weight: $fw-bold;
					margin-bottom: 5px;
					color: $gray-2;
				}

				.seller-rating {
					margin-bottom: 44px;

					.rating-wrap {
						display: flex;

						.rating-image {
							width: 128px;
							margin-right: 9px;

							img {
								display: block;
								width: 100%;
							}
						}

						.rating-number {
							color: $gray-2;
							@include fontsize(13px);
							margin-top: 8px;
						}
					}
				}

				.services-block {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: auto;

					.price {
						@include fontsize(20px);
						font-weight: $fw-bold;
						color: $gray-3;
					}

					.wishlist-btn {
						.icon-heart {
							background: url(../../images/icons/icon-heart-big.svg) no-repeat center;
							width: 32px;
							height: 28px;
							display: block;
						}
					}
				}
			}
		}
	}
}

// Add Banners
.ads-banners {
	padding: 30px 0;

	.slick-slider {
		overflow: hidden;

		@media all and (min-width: 1200px) {
			margin: 0 -14px;
		}

		.card {
			display: block;
			overflow: hidden;
			padding: 0;

			@media all and (min-width: 1200px) {
				min-height: 240px;
			}
		}

		.btn {
			@include fontsize(14px);
			padding: 5px 10px;

			@media all and (min-width: 768px) {
				@include fontsize(16px);
				padding: 7px 22px;
			}
		}

		.images-wrapper {
			height: 200px;

			@media all and (min-width: 1200px) {
				height: 321px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
				object-position: 0 0;
			}
		}

		.ad-info {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 10px 0 10px 10px;

			@media all and (min-width: 1200px) {
				padding-left: 32px;
				width: 40%;
			}

			.info-wrap {
				display: block;
				margin-bottom: 10px;
				font-weight: $fw-bold;
				color: $gray-3;
				text-transform: capitalize;
				letter-spacing: -0.2px;

				@media all and (min-width: 768px) {
					@include fontsize(18px);
				}

				@media all and (min-width: 992px) {
					@include fontsize(20px);
					margin-bottom: 24px;
				}

				@media all and (min-width: 1200px) {
					@include fontsize(24px);
				}
			}
		}

		.slick-dots {
			display: flex !important;
			justify-content: center;
			margin-top: 10px;

			@media all and (min-width: 768px) {
				margin-top: 14px;
			}

			li {
				opacity: 0.3;
				transition: 0.3s;
				margin: 0 3px;

				&.slick-active {
					opacity: 1;

					button {
						width: 24px;
					}
				}

				button {
					font-size: 0;
					width: 16px;
					height: 3px;
					background: $gray-3;
					transition: 0.3s;
				}
			}
		}

		.slick-slide {
			@media all and (min-width: 1200px) {
				padding: 0 14px;
			}
		}
	}

	&.last {
		margin-bottom: 20px;
	}
}

.popular-tests {
	.slick-prev {
		bottom: 50%;
		transform: translateY(50%) rotate(180deg);
	}

	.slick-next {
		bottom: 50%;
		transform: translateY(50%);
	}

	.slick-slider {
		.slick-list {
			overflow: hidden !important;
		}
	}
}

// Core Services
.core-services {
	padding: 20px 0;

	@media all and (min-width: 1200px) {
		padding-bottom: 37px;
	}

	h2 {
		text-align: center;
		margin-bottom: 20px;

		@media all and (min-width: 1200px) {
			margin-bottom: 27px;
		}
	}

	p {
		text-align: center;
		color: $gray-5;
		line-height: 1.5;
		letter-spacing: 0.1px;
		margin-bottom: 20px;

		@media all and (min-width: 768px) {
			padding: 0 12%;
		}

		@media all and (min-width: 1200px) {
			padding: 0 20%;
			margin-bottom: 30px;
		}

		@media all and (min-width: 1440px) {
			padding: 0 25%;
			margin-bottom: 40px;
		}
	}

	.services-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -10px;

		@media all and (min-width: 1440px) {
			margin: 0 -14px;
		}
	}

	.service-wrap {
		position: relative;
		width: 100%;
		max-width: 350px;
		height: 279px;
		margin: 0 10px 20px;
		@include radius(4px);
		overflow: hidden;

		&.stats-services {
			box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
			background-color: $white;

			a:hover .service-info {
				background: transparent;
			}

			.img-wrap {
				position: static;
				width: 100px;
				height: 100px;
				margin: 0 auto 6px;

				&::before {
					display: none;
				}

				&::after {
					display: none;
				}

				img {
					object-fit: contain;
				}
			}

			.service-info {
				height: auto;
				color: $black;
				padding: 0;
				margin-bottom: 6px;
			}

			p {
				padding: 0px 10px;
			}
		}

		@media all and (min-width: 768px) {
			width: calc(33% - 20px);
		}

		@media all and (min-width: 1200px) {
			width: calc(25% - 20px);
		}

		@media all and (min-width: 1440px) {
			margin: 0 14px;
			margin-bottom: 28px;
			width: calc(25% - 28px);
		}

		a {
			position: relative;
			display: block;
			height: 100%;

			&:hover {
				.service-info {
					align-items: center;
					background: linear-gradient(47.22deg, #5e86ef 5.72%, #7a9cf3 94.27%);
					opacity: 0.9;
				}
			}
		}

		.img-wrap {
			@include absolute(0, 0, 0, 0);
			width: 100%;
			height: 100%;
			z-index: -1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				@include radius(4px);
				display: block;
			}

			&:before {
				content: "";
				width: 100%;
				height: 100%;
				@include absolute(0, 0, 0, 0);
				background-image: inherit;
				filter: blur(5px);
				z-index: 2;
			}

			&:after {
				content: "";
				width: 100%;
				height: 70%;
				min-height: 190px;
				background: linear-gradient(0deg,
						rgba(31, 25, 21, 0.6) 0%,
						rgba(90, 81, 78, 0) 100%);
				@include absolute(auto, 0, 0, 0);
				z-index: 1;
			}
		}

		.service-info {
			color: $white;
			font-weight: $fw-bold;
			text-align: center;
			padding: 18px 30px;
			transition: 0.3s;
			background: linear-gradient(47.22deg,
					transparent 5.72%,
					transparent 94.27%);
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			@media all and (min-width: 768px) {
				@include fontsize(18px);
			}

			@media all and (min-width: 992px) {
				@include fontsize(20px);
			}

			@media all and (min-width: 1200px) {
				@include fontsize(24px);
			}
		}
	}

	&.core-services-wrap {
		.slick-slider {
			.slick-list {
				overflow: hidden !important;
			}
		}

		.slick-prev {
			bottom: 50%;
			transform: translateY(50%) rotate(180deg);
		}

		.slick-next {
			bottom: 50%;
			transform: translateY(50%);
		}

		.card {
			padding: 0;
		}

		.service-img {
			width: 100%;
			height: 266px;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.service-name {
			padding: 20px;
			text-align: center;

			@media all and (min-width: 1200px) {
				padding: 30px 20px;
			}
		}
	}
}

.lab-name {
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 10px;
	color: #9A9FA5;
}