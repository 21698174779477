.seller-account-complete{
	padding: 30px 0;
	@media all and (min-width: 768px){
		padding: 60px 0;
	}
	.card{
		text-align: center;
		max-width: 505px;
		margin: 0 auto;
		h2{
			margin-bottom: 30px;
			color: $blue;
		}
		p{
			margin-bottom: 20px;
		}
	}
}