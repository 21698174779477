.cancel-order-block {
    position: relative;
    padding: 61px 30px 81px;;
}
.cross-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: bold;
    cursor: pointer;
}
.required-block::before {
    display: none !important;
    margin-right: 0px !important;
}
.required-block::after {
    display: inline-block;
    content: '';
    background: url(../assets/images/icons/icon-star.svg) no-repeat;
    width: 7px;
    height: 7px;
    margin-left: 4px;
}
.verfiy-number-box {
    width: 100%;
    margin-bottom: 11px;
}
.verfiy-number-box .styles_react-code-input-container__tpiKG div input {
    width: 25% !important;
}
.input-wrap-block {
    margin-bottom: 11px;
}
.input-label-block::before {
    display: none !important;
    margin-right: opx !important;
}
.input-label-block::after{
    display: inline-block;
    content: '';
    background: url(../assets/images/icons/icon-star.svg) no-repeat;
    width: 7px;
    height: 7px;
    margin-left: 4px;
}
.otp-verfiy-block {
    width: 100% !important;
}