.create-account{
	padding: 30px 0;
	@media all and (min-width: 1200px){
		padding: 56px 0 43px;
	}
	.container{
		max-width: 720px;
	}
	.card{
		margin-bottom: 16px;
		@media all and (min-width: 768px){
			padding: 30px 50px 60px;
		}
		@media all and (min-width: 1440px){
			padding: 54px 100px 130px;
		}
		@media all and (min-width: 1300px) and (max-width: 1400px){
			padding: 30px 93px 60px;
		}
		&:nth-child(2){
			@media all and (min-width: 1440px){
				padding: 33px 100px 35px;
			}
			@media all and (min-width: 1300px) and (max-width: 1400px){
				padding: 33px 93px 35px;
			}
		}
	}
	h2{
		text-align: center;
	}
	.input-block{
		margin-bottom: 30px;
		.input-wrap{
			position: relative;
		}
		.toggle-wrap{
			@include absolute(50%, 12px);
			z-index: 1;
			transform: translate(0, -50%);
			button{
				display: block;
			}
			.icon-eye{
				display: block;
				width: 16px;
				height: 16px;
				background: url(../../images/icons/icon-eye-2.svg) no-repeat;
			}
			button{
				&.show{
					.icon-eye{
						background: url(../../images/icons/icon-eye-4.svg) no-repeat;
					}
				}
			}
		}
		&.pr-fix{
			@media all and (min-width: 1440px){
				padding-right: 8px;
			}
		}
		&.pl-fix{
			@media all and (min-width: 1440px){
				padding-left: 8px;
			}
		}
	}
	.input-wrapper{
		margin-bottom: 0;
	}
	.password-hint{
		font-family: $third-fontfamily;
		@include fontsize(14px);
		color: $gray-2;
		margin: 3px 0 2px;
	}
	label{
		color: $gray-3;
		margin-bottom: 5px;
		@media all and (min-width: 1200px){
			margin-bottom: 11px;
		}
	}
	.form-check-label{
		letter-spacing: 0.13px;
		a{
			color: $blue;
			  text-decoration: underline;
		}
	}
	.btn-wrap{
		margin-top: 10px;
		@media all and (min-width: 768px){
			margin-top: 19px;
		}
	}
	.btn{
		@include fontsize(16px);
	}
	.signin-wrap{
		.signin-link{
			color: $blue;
			font-weight: $fw-semibold;
			margin-top: 0;
			margin-left: 24px;
			padding-bottom: 2px;
			border-bottom: 1px solid $blue ;
		}
	}
	.error{
		padding: 0;
	}
}

.create-account{
	.send-button-otp{
		color: #69a1f4;
		font-size: 14px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 14px;
		background: transparent;
	}
	.mobile-otp{
		display: flex;
		align-items: center;
		.security-input{
			> div{
				width: auto !important;
				> div{
					display: flex;
				}
			}
			input{
				margin-right: 6px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	.mobile-input{
		width: 100%;
		&.has-otp{
			width: calc(72% - 20px);
			margin-right: 20px;
		}
	}
	.otp-create-verify{
		width: 28%;
	}
	.timer-user{
		position: absolute;
		top: 100%;
		transform: translateY(4px);
    	right: 9px;
		font-size: 12px;
	}
}