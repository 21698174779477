.edit-information.profile {
    padding-top: 19px;
    overflow: hidden;
    &.listing{
        &.after-fix{
            overflow: unset;
            .description-wrap{
                @media all and (min-width:768px){
                    &.listing{
                        position: relative;
                        .image-block{
                            position: sticky;
                            top: 20px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width: 1200px) {
        padding: 19px 0 20px 0;
    }

    @media all and (min-width: 1300px) and (max-width: 1400px) {
        padding: 23px 0 20px 0;
    }

    .select-wrap {
        &>div {
            width: 140px;

            @media all and (min-width: 1200px) {
                width: 177px;
            }

            @media all and (min-width: 1300px) and (max-width: 1400px) {
                width: 166px;
            }
        }

        .react-select__single-value {
            @media all and (min-width: 1200px) {
                @include fontsize(20px);
            }
        }

        .react-select__placeholder {
            @media all and (min-width: 1200px) {
                @include fontsize(20px);
            }
        }
    }

    .reviews {
        .review-box {
            position: relative;
            padding-right: 50px;

            .more-wrap {
                position: absolute;
                top: 10px;
                right: 10px;

                .menu-wrap {
                    margin-top: 10px;
                }
            }
        }
    }
}

.tabbing-head {
    &.listing {
        &.detail-listing {
            @media all and (min-width: 768px) {
                display: block;
            }

            .tabbing-select {
                margin-left: auto;
            }
        }
    }
}

.react-select-container {
    &.single {
        min-width: 180px;
    }
}

.tabbing-head {
    margin-bottom: 40px;

    @media all and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    @media all and (min-width: 1300px) and (max-width: 1400px) {
        margin-bottom: 60px;
    }

    @media all and (min-width: 1440px) {
        margin: 58px 0 55px;
    }

    .tabbing-select {
        margin-bottom: 20px;

        @media all and (min-width: 768px) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            order: 2;
            width: 42%;
            margin-bottom: 7px;
        }

        @media all and (min-width: 992px) {
            width: 35%;
        }

        h3 {
            margin-right: 10px;
            color: $gray-5;
            @include fontsize(14px);
            margin-bottom: 10px;

            @media all and (min-width: 768px) {
                margin-bottom: 0;
            }

            @media all and (min-width: 1200px) {
                @include fontsize(16px);
            }
        }

        .select-wrap {
            &>div {
                width: 100%;

                @media all and (min-width: 768px) {
                    width: auto;
                }
            }
        }
    }

    .tabs-wrap {
        border-bottom: none;

        li {
            .nav-link {
                @include fontsize(16px);
                padding-bottom: 12px;
            }
        }

        @media all and (min-width: 768px) {
            order: 1;
            width: 58%;
            margin-bottom: 0 !important;
            margin-top: 9px;
        }

        @media all and (min-width: 992px) {
            width: 65%;
        }
    }

    .tab-content {
        position: relative;

        &::after {
            position: absolute;
            top: -29px;
            left: 0;
            width: 100%;
            height: 1px;
            content: "";
            background: #dee2e6;
        }

        @media all and (min-width: 768px) {
            order: 3;
            width: 100%;
            margin-top: 29px;
        }

        @media all and (min-width: 1440px) {
            margin-top: 24px;

            &::after {
                top: -25px;
            }
        }
    }
}

.description-wrap {
    background: $white;
    box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
    border-radius: 4px;
    padding: 15px 12px;

    @media all and (min-width: 768px) {
        display: flex;
        align-items: flex-start;
    }

    @media all and (min-width: 1300px) {
        padding: 20px 23px;
        justify-content: space-between;
    }

    @media all and (min-width: 1440px) {
        padding: 30px 22px 33px 22px;
    }

    .image-block {
        .image-wrap {
            margin-bottom: 20px;

            @media all and (min-width: 768px) {
                width: 250px;
                height: 250px;
            }

            @media all and (min-width: 1200px) {
                margin-bottom: 24px;
            }

            @media all and (min-width: 1300px) and (max-width: 1400px) {
                width: 303px;
                height: 303px;
            }

            @media all and (min-width: 1400px) {
                width: 320px;
                height: 320px;
            }

            img {
                display: block;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }

        .btn {
            border: 2px solid $blue;
            box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
            border-radius: 2px;
            display: block;
            font-weight: $fw-bold;
            @include fontsize(16px);
        }
    }

    .seller-details {
        @media all and (min-width: 768px) {
            width: calc(100% - 250px);
            padding-left: 30px;
        }

        @media all and (min-width: 1200px) {
            width: calc(100% - 320px);
        }

        @media all and (min-width: 1300px) and (max-width: 1400px) {
            width: calc(100% - 326px);
        }

        @media all and (min-width: 1440px) {
            padding-left: 58px;
        }

        .details-head {
            margin-bottom: 20px;

            @media all and (min-width: 1200px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            @media all and (min-width: 1300px) and (max-width: 1400px) {
                align-items: flex-start;
                margin-bottom: 14px;
            }

            .main-head {
                margin-bottom: 15px;

                @media all and (min-width: 1200px) {
                    margin-bottom: 0;
                }

                .seller-btns {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    @media all and (min-width: 1300px) and (max-width: 1400px) {
                        margin-bottom: 25px;
                    }

                    @media all and (min-width: 1440px) {
                        margin-bottom: 27px;
                    }

                    .btn-wrap {
                        display: flex;
                        align-items: center;
                        color: $blue;
                        margin-right: 18px;
                        @include fontsize(13px);
                        transition: all 0.5s;
                        background: transparent;

                        &:hover {
                            opacity: 0.8;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        @media all and (min-width: 768px) {
                            margin-top: 0;
                        }

                        @media all and (min-width: 1200px) {
                            margin-right: 42px;
                            @include fontsize(16px);
                        }

                        .icon-heart {
                            background: url(../../images/icons/icon-heart.svg) no-repeat center;
                            width: 18px;
                            height: 16px;
                            margin: 1px 4px 0 0;
                            background-size: 18px auto;

                            @media all and (min-width: 1200px) {
                                width: 24px;
                                height: 22px;
                                margin: 3px 9px 0 0;
                                background-size: 24px auto;
                            }
                        }

                        .icon-share {
                            background: url(../../images/icons/icon-share.svg) no-repeat center;
                            width: 16px;
                            height: 18px;
                            margin: 1px 4px 0 0;
                            background-size: 16px auto;

                            @media all and (min-width: 1200px) {
                                width: 20px;
                                height: 22px;
                                margin: 3px 9px 0 0;
                                background-size: 20px auto;
                            }
                        }
                    }
                }

                h2 {
                    margin-bottom: 0;
                    color: $gray-5;
                    @include fontsize(20px);

                    @media all and (min-width: 1200px) {
                        @include fontsize(24px);
                    }
                }
            }

            .pricing-wrap {
                color: $gray-3;
                @include fontsize(23px);
                font-weight: $fw-bold;
                border: 0.5px solid $gray;
                border-radius: 2px;
                padding: 13px 8px;
                display: inline-block;

                @media all and (min-width: 1200px) {
                    @include fontsize(32px);
                    padding: 18px;
                }

                @media all and (min-width: 1300px) and (max-width: 1400px) {
                    padding: 22px;
                }
            }
        }

        .seller-rating {
            display: flex;
            margin-bottom: 15px;

            @media all and (min-width: 1300px) {
                margin-bottom: 20px;
            }

            .rating-wrap {
                display: flex;
                border: 0.5px solid #F1F2F4;
                box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
                border-radius: 4px;
                padding: 7px 15px;

                .rating-image {
                    width: 128px;
                    margin-right: 4px;

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                .rating-number {
                    color: $gray-2;
                    @include fontsize(13px);
                    margin-top: 4px;
                }
            }
        }

        .test-details {
            .test-info {
                display: inline-block;
                color: #505357;
                @include fontsize(16px);
                font-weight: 700;
                margin: 0 10px 11px 0;
            }
        }

        .details-wrap {
            margin-bottom: 18px;

            @media all and (min-width: 768px) {
                display: flex;
                align-items: flex-start;
            }

            .detail-list {
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }

                @media all and (min-width: 768px) {
                    margin: 0 45px 0 0;

                    &:last-child {
                        margin: 0;
                    }
                }

                @media all and (min-width: 1300px) and (max-width: 1400px) {
                    margin-right: 75px;
                }

                @media all and (min-width: 1440px) {
                    margin: 0 62px 0 0;
                }

                h3 {
                    color: $gray-5;
                    margin-bottom: 15px;
                    @include fontsize(16px);
                }

                p {
                    color: $gray-3;
                    line-height: 1.5;
                }

                .details-values {
                    li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .detail-text {
                            color: $gray-3;
                            font-weight: $fw-bold;
                            border-right: 2px solid $blue;
                            padding: 0 5px 1px 0;
                            margin-right: 5px;
                            line-height: 0.8;

                            &:last-child {
                                border-right: none;
                                padding-right: 0;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        p {
            color: $gray-5;
            letter-spacing: 0.08px;

            @media all and (min-width: 1440px) {
                margin-right: 12px;
            }
        }
    }
}

.services {
    padding-bottom: 22px;

    @media all and (min-width: 1200px) {
        padding-bottom: 31px;
    }

    .services-head {
        margin-bottom: 15px;
        position: relative;

        &::after {
            background: #D6D9DE;
            position: absolute;
            bottom: -9px;
            left: 0;
            width: 100%;
            height: 1px;
            content: "";
        }

        @media all and (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            &::after {
                bottom: -9px;
            }
        }

        h2 {
            margin-bottom: 0;
            color: $gray-3;
            @include fontsize(20px);

            @media all and (min-width: 1200px) {
                @include fontsize(24px);
            }
        }

        .services-select {
            @media all and (min-width: 768px) {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 43%;
            }

            @media all and (min-width: 1200px) {
                width: 35%;
            }

            h3 {
                margin-right: 10px;
                color: #505357;
                @include fontsize(14px);
                margin-bottom: 10px;

                @media all and (min-width: 768px) {
                    margin-bottom: 0;
                }

                @media all and (min-width: 1200px) {
                    @include fontsize(16px);
                }
            }

            .select-wrap {
                &>div {
                    width: 100%;

                    @media all and (min-width: 768px) {
                        width: 140px;
                    }

                    @media all and (min-width: 1200px) {
                        width: 156px;
                    }

                    @media all and (min-width: 1300px) and (max-width: 1400px) {
                        width: 150px;
                    }
                }

                .react-select__single-value {
                    @media all and (min-width: 1200px) {
                        @include fontsize(16px);
                    }
                }

                .react-select__placeholder {
                    @media all and (min-width: 1200px) {
                        @include fontsize(16px);
                    }
                }
            }
        }
    }

    .services-wrap {
        @media all and (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @media all and (min-width: 1200px) {
            justify-content: flex-start;
            margin-right: -20px;
        }

        .card-wrap {
            @media all and (min-width: 1200px) {
                margin-right: 20px;
                margin-bottom: 27px;
                width: calc(25% - 20px);
            }
        }
    }
}

.services-inputs {
    margin: 20px 0;

    @media all and (min-width: 768px) {
        margin: 0 0 28px;
    }

    form {
        .form-wrap {
            @media all and (min-width: 768px) {
                display: flex;
                justify-content: space-between;
            }

            .inputs-wrap {
                @media all and (min-width: 768px) {
                    display: flex;
                    flex-grow: 1;
                }

                .input-wrap {
                    position: relative;
                    margin-bottom: 10px;

                    @media all and (min-width: 768px) {
                        margin: 0 0 0 8px;
                        flex-grow: 1;
                        max-width: 438px;

                        &:first-child {
                            margin: 0;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .input {
                        width: 100%;
                        background: $white;
                        border: 1px solid #F1F2F4;
                        box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
                        border-radius: 2px;
                        padding: 10px 44px 10px 12px;

                        &::placeholder {
                            color: #9A9FA5;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    .search-btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        padding: 6px 12px 0;
                        background: transparent;
                        border-left: 1px solid #e7e7e7;

                        .icon-search {
                            background: url(../../images/icons/icon-magnifier.svg) no-repeat center;
                            width: 18px;
                            height: 18px;
                            display: inline-block;
                        }
                    }
                }
            }

            .selects-wrap {
                @media all and (min-width: 768px) {
                    display: flex;
                }

                .select-wrap {
                    @media all and (min-width: 768px) {
                        width: 84px;
                        margin-left: 8px;

                        &.bigger {
                            width: 121px;
                        }

                        &.selecet-box-wrapper {
                            width: 135px;

                            @media all and (min-width: 1024px) {
                                width: 200px;
                            }
                        }
                    }

                    &>div {
                        width: 100%;
                        margin-top: 10px;

                        @media all and (min-width: 768px) {
                            margin-top: 0;
                            height: 100%;
                        }
                    }

                    .react-select__control {
                        @media all and (min-width: 768px) {
                            height: 100%;
                        }
                    }

                    &.disabled {
                        .react-select__single-value {
                            color: #9A9FA5;
                        }

                        .react-select__placeholder {
                            color: #9A9FA5;
                        }
                    }

                    .react-select__single-value {
                        color: #505357;
                        @include fontsize(16px);
                    }

                    .react-select__placeholder {
                        color: #505357;
                        @include fontsize(16px);
                    }
                }
            }
        }
    }

    .form-replica {
        .form-wrap {
            @media all and (min-width: 768px) {
                display: flex;
                justify-content: space-between;
            }

            .inputs-wrap {
                @media all and (min-width: 768px) {
                    display: flex;
                    flex-grow: 1;
                }

                .input-wrap {
                    position: relative;
                    margin-bottom: 10px;

                    @media all and (min-width: 768px) {
                        margin: 0 0 0 8px;
                        flex-grow: 1;
                        max-width: 438px;

                        &:first-child {
                            margin: 0;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .input {
                        width: 100%;
                        height: 100%;
                        background: $white;
                        border: 1px solid #f1f2f4;
                        box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
                        border-radius: 2px;
                        padding: 10px 44px 10px 12px;

                        &::placeholder {
                            color: #9a9fa5;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    .search-btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        padding: 6px 12px 0;
                        background: transparent;
                        border-left: 1px solid #e7e7e7;

                        .icon-search {
                            background: url(../../images/icons/icon-magnifier.svg) no-repeat center;
                            width: 18px;
                            height: 18px;
                            display: inline-block;
                        }
                    }
                }
            }

            .selects-wrap {
                @media all and (min-width: 768px) {
                    display: flex;
                }

                .select-wrap {
                    @media all and (min-width: 768px) {
                        width: 84px;
                        margin-left: 8px;

                        &.bigger {
                            width: 121px;
                        }
                    }

                    &.state-city {
                        @media all and (min-width: 768px) {
                            width: 150px;
                        }
                    }

                    &>div {
                        width: 100%;
                        margin-top: 10px;

                        @media all and (min-width: 768px) {
                            margin-top: 0;
                            height: 100%;
                        }
                    }

                    .react-select__control {
                        @media all and (min-width: 768px) {
                            height: 100%;
                        }
                    }

                    &.disabled {
                        .react-select__single-value {
                            color: #9a9fa5;
                        }

                        .react-select__placeholder {
                            color: #9a9fa5;
                        }
                    }

                    .react-select__single-value {
                        color: #505357;
                        @include fontsize(16px);
                    }

                    .react-select__placeholder {
                        color: #505357;
                        font-size: 16px !important;
                    }
                }
            }
        }
    }
}



.card-wrap {
    height: 100%;
        margin-bottom: 0;

    @media all and (min-width: 768px) {
        width: calc(50% - 10px);
        
    }

    @media all and (min-width: 1200px) {
        width: calc(33% - 20px);
        margin-bottom: 27px;
    }

    .service-block {
        height: 100%;
        background: $white;
        box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
        border-radius: 4px;
        transition: all 0.5s;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        .contains-test {
            position: absolute;
            top: 7px;
            left: 0;
            background: #69A1F4;
            color: #ffffff;
            padding: 2px 8px;
            font-size: 13px;
        }

        &:hover {
            opacity: 1;
            box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.3);
        }

        .service-image {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            height: 165px;

            @media all and (min-width: 1200px) {
                height: 180px;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .service-card {
            padding: 17px 16px 18px 15px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .service-name {
                @include fontsize(20px);
                font-weight: $fw-semibold;
                color: $gray-3;
                margin-bottom: 10px;
            }

            .location {
                color: $gray-5;
                @include fontsize(16px);
                font-weight: $fw-bold;
                margin-bottom: 5px;
            }

            .address {
                color: $gray-5;
                @include fontsize(16px);
                margin-bottom: 10px;
                font-weight: 400;
            }

            .seller-rating {
                margin-bottom: 44px;

                .rating-wrap {
                    display: flex;

                    .rating-image {
                        width: 128px;
                        margin-right: 9px;

                        img {
                            display: block;
                            width: 100%;
                        }
                    }

                    .rating-number {
                        color: $gray-2;
                        @include fontsize(13px);
                        margin-top: 8px;
                    }
                }
            }

            .services-block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: auto;

                .price {
                    @include fontsize(20px);
                    font-weight: $fw-bold;
                    color: $gray-3;
                }

                .wishlist-btn {
                    .icon-heart {
                        background: url(../../images/icons/icon-heart-big.svg) no-repeat center;
                        width: 32px;
                        height: 28px;
                        display: block;
                    }
                }
            }
        }
    }
}

.pagination-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 11px -5px 0 0;

    .pagination {
        .page-item {
            margin: 0 5px;

            &.disabled {
                .page-link {
                    .icon-arrow {
                        opacity: 0.2;
                    }
                }
            }

            &.previous {
                .page-link {
                    .icon-arrow {
                        transform: rotate(180deg);
                    }
                }
            }

            &.active {
                .page-link {
                    border: 1px solid $blue;
                    color: $blue;
                }
            }

            .page-link {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #D6D9DE;
                border-radius: 2px;
                color: $gray-3;
                background: transparent;
                padding: 3px;
                width: 32px;
                transition: all 0.5s;

                &:hover {
                    opacity: 0.7;
                }

                &:focus {
                    box-shadow: none;
                }

                .icon-arrow {
                    background: url(../../images/icons/icon-arrow.svg) no-repeat center;
                    width: 7px;
                    height: 12px;
                    display: block;
                }
            }
        }
    }
}

.reviews {
    padding: 22px 0 35px 0;

    @media all and (min-width: 1200px) {
        padding: 31px 0 50px 0;
    }

    .review-head {
        margin-bottom: 20px;
        position: relative;

        &::after {
            position: absolute;
            background: $gray;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 1px;
            content: "";

            @media all and (min-width: 1200px) {
                bottom: -15px;
            }
        }

        @media all and (min-width: 1200px) {
            margin-bottom: 38px;
        }

        h2 {
            margin-bottom: 0;
            color: $gray-3;
            @include fontsize(20px);

            @media all and (min-width: 1200px) {
                @include fontsize(24px);
            }
        }
    }

    .reviews-wrap {
        .review-box {
            box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
            border-radius: 4px;
            background: $white;
            padding: 15px 10px;
            display: flex;
            margin-bottom: 8px;
            align-items: flex-start;

            &:last-child {
                margin-bottom: 0;
            }

            @media all and (min-width: 1200px) {
                padding: 16px 23px;
            }

            .review-logo {
                margin-top: 20px;

                @media all and (min-width: 1200px) {
                    margin-top: 25px;
                }

                .text-image,
                .user-img {
                    flex-shrink: 0;
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    overflow: hidden;
                    background: #EAF1FF;
                    border-radius: 50%;
                    line-height: 1.5;
                    color: $blue;
                    @include fontsize(14px);
                    font-family: $third-fontfamily;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }
                }
            }

            .review-text {
                margin-left: 13px;

                @media all and (min-width: 1200px) {
                    margin-left: 17px;
                }

                .review-details {
                    margin-bottom: 20px;

                    @media all and (min-width: 768px) {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 10px;
                    }

                    @media all and (min-width: 1200px) {
                        margin-bottom: 19px;
                    }

                    .review-info {
                        .review-name {
                            color: $gray-5;
                            font-weight: $fw-bold;
                            @include fontsize(16px);
                            margin-bottom: 4px;
                        }

                        .review-timing {
                            color: $gray-2;
                            @include fontsize(13px);
                            margin-bottom: 10px;

                            @media all and (min-width: 768px) {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .review-rating {
                        width: 128px;

                        img {
                            display: block;
                            width: 100%;
                        }
                    }
                }

                p {
                    color: $gray-5;
                    @include fontsize(16px);
                    letter-spacing: 0.05px;
                }
            }
        }
    }

    .pagination-wrap {
        margin-top: 38px;
    }
}

.tests-wrap {
    display: flex;
    flex-wrap: wrap;

    @media all and (min-width: 1200px) {
        margin-bottom: 11px;
    }

    li {
        padding-right: 5px;
        margin: 0 5px 7px 0;
        color: $gray-2;
        @include fontsize(13px);
        border-right: 1px solid $blue;

        &:last-child {
            border-right: none;
        }

        @media all and (min-width: 1200px) {
            margin-bottom: 0;
        }
    }
}

.tab-wrapper {
    .experience-wrap {
        background: $white;
        box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
        border-radius: 4px;
        padding: 15px 12px;
        margin-bottom: 40px;

        @media all and (min-width: 1200px) {
            padding: 31px 24px 42px;
            margin-bottom: 55px;
        }

        .naming {
            color: $gray-3;
            font-weight: $fw-semibold;
            @include fontsize(18px);
            margin-bottom: 10px;

            @media all and (min-width: 1200px) {
                @include fontsize(20px);
            }
        }

        .date-wrap {
            color: $gray-2;
            font-weight: $fw-bold;
            @include fontsize(16px);
            margin-bottom: 15px;

            @media all and (min-width: 1200px) {
                margin-bottom: 26px;
            }
        }

        p {
            color: $gray-5;
            @include fontsize(16px);
            margin-bottom: 25px;
            letter-spacing: 0.08px;

            @media all and (min-width: 1200px) {
                margin-bottom: 27px;
            }
        }
    }
}

.view-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (min-width: 1200px) {
        padding-right: 24px;
    }

    &::before {
        flex-grow: 1;
        content: "";
        height: 1px;
        background: $gray;
        margin-top: 7px;
    }

    .view-more {
        color: $blue;
        font-weight: $fw-semibold;
        @include fontsize(16px);
        position: relative;
        margin-left: 20px;

        @media all and (min-width: 1200px) {
            margin-left: 39px;
        }

        &::after {
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 1px;
            content: "";
            background: $blue;
        }
    }
}

.entertainment-tab.portfolio-sliders {
    .slick-slider {
        @media all and (min-width: 1200px) {
            margin-bottom: 59px;
        }
    }

    .certificate-slider {
        .slick-slider {
            @media all and (min-width: 1200px) {
                margin-bottom: 12px;
            }
        }
    }
}

.certificate-slider{
    &.lab-certificate{
        .slick-slider {
            .slick-list{
                overflow: hidden !important;
            }
            .slick-arrow{
                bottom: 50%;
                transform: translateY(50%);
                &.slick-prev{
                    transform: translateY(50%) rotate(180deg);
                }
            }
            .img-wrap{
                height: 100%;
                a{
                    height: 100%;
                    img{
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}


.new-tabbing-head {
    display: block;

    .tabbing-select {
        margin-left: auto;

        @media all and (min-width: 768px) {
            width: 45%;
        }

        @media all and (min-width: 992px) {
            width: 35%;
        }
    }
}

.width-full-drpdown {
    &.select-wrap {
        .react-select__menu {
            width: 100% !important;
        }
    }
}

.test-details-info {
    padding: 10px 0 0;

    .test-details {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;

        @media all and (min-width: 768px) {
            margin-bottom: 30px;
            flex-wrap: nowrap;
            align-items: center;
        }

        &.days-list-wrap {
            @media all and (min-width: 768px) {
                margin-bottom: 17px;
            }
        }

        .test-name {
            // margin-bottom: 8px;
            margin-right: 5px;
            flex-shrink: 0;
            span {
                display: inline-block;
                @include fontsize(16px);
                font-weight: $fw-semibold;
                font-family: $primary-fontfamily;
            }

            address {
                display: inline-block;
                @include fontsize(16px);
                font-weight: $fw-semibold;
                font-family: $primary-fontfamily;
            }

            @media all and (min-width: 768px) {
                width: 28%;
                margin-bottom: 0;
            }

            @media all and (min-width: 1024px) {
                width: 22%;
            }
        }

        .test-list {
            display: flex;
            flex-wrap: wrap;

            span {
                display: inline-block;
                @include fontsize(14px);
                font-weight: $fw-medium;
                font-family: $primary-fontfamily;
            }

            .days {
                background-color: #F6F6F6;
                padding: 5px;
                text-align: center;
                margin-bottom: 10px;
                font-family: $primary-fontfamily;

                @media all and (min-width: 768px) {
                    min-width: 163px;
                    margin: 0 10px 10px 0px;
                }
            }

            @media all and (min-width: 768px) {
                width: 70%;
            }

            @media all and (min-width: 1024px) {
                width: 80%;
            }
        }
    }

    .test-content {
        line-height: 1.6;
    }
}

.do-dont-accordion{
    .accordion-button{
        padding: 0 !important;
        background-image: none !important;
    }
    .accordion-item{
        box-shadow: none;
    }
    .accordion-body{
        padding: 0 !important;
        .do-or-dont {
            width: 100%;
        }
    }
}

.do-or-dont {
    background-color: #69A1F408;
    padding: 15px;

    @media all and (min-width: 768px) {
        padding: 25px;
    }


    .do-list {
        display: block;
        @include fontsize(16px);
        font-weight: $fw-semibold;
        background-color: #009F3A1A;
        padding: 5px;
        border-radius: 2px;
        font-family: $primary-fontfamily;
        width: 100%;
    }
    
    .dont-list {
        width: 100%;
        display: block;
        @include fontsize(16px);
        font-weight: $fw-semibold;
        background-color: #E300161A;
        padding: 5px;
        border-radius: 2px;
        font-family: $primary-fontfamily;
    }

    .dont-list-wrapper {
        display: block;
        width: 100%;
    }

    ul {
        margin: 20px 0;
        max-height: 150px;
        overflow-y: auto;
        width: 100%;

        li {
            display: block;
            padding-left: 20px;
            position: relative;
            font-family: $primary-fontfamily;

            &::after {
                position: absolute;
                content: "";
                left: 5px;
                top: 11px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #000;
            }

            :last-child {
                margin-bottom: 15px;
            }
        }
    }
}

.test-content-wrap {
    background-color: #69A1F408;
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    width: 100%;

    span {
        font-weight: $fw-semibold;
    }

    &::after {
        display: inline-block;
        position: absolute;
        content: "";
        right: 25px;
        width: 10px;
        height: 6px;
        background-image: url("../../images/icon-down-view.svg");
        background-repeat: no-repeat;
        background-size: contain;
        transition: all 0.5s;
        top: 50%;
        transform: translateY(-50%);
    }
    &.active{
        &::after{
            transform: translateY(-50%) rotate(180deg);
        }
    }
}