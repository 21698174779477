/*
 Font-family
 -------------------------*/
$primary-fontfamily:'Lato';
$secondary-fontfamily:'Noto Sans';
$third-fontfamily:'Roboto';

/*
 Font-color
 -------------------------*/
$white:#ffffff;
$black:#000000;
$gray: #D6D9DE;
$gray-2: #9A9FA5;
$gray-3: #13171C;
$gray-3-transparent: rgba(19, 23, 28, 0.88);
$gray-4-transparent: rgba(19, 23, 28, 0.5);
$gray-5-transparent: rgba(241, 242, 244, 0.32);
$gray-6-transparent: rgba(89, 97, 117, 0.08);;
$gray-4: #F1F2F4;
$gray-5: #505357;
$gray-6: #BCBEC0;
$gray-11: hsla(220, 12%, 95%, 0.32);
$gray-8: #F0F0F0;
$gray-10: #EFEFEF;
$gray-12: #E5E5E5;
$gray-12: #f1f1f1;
$gray-9: #D6E4FF;
$blue: #69A1F4;
$blue-transparent: rgba(105, 161, 244, 0.5);
$blue-2: #74adf0;
$blue-3: #7a9cf3;
$blue-light: #EAF1FF;
$red: #ff0000;
$red-2: #F46E66;
$red-3: #E1592F;
$off-white: #F6F6F7;
$yellow: #FFE58F;
$yellow-light: #FFFBE6;
$blue-transparent: rgba(86, 110, 146, 0.16);
$blue-transparent-2: rgba(89, 97, 117, 0.08);
$green: #009F3A;

/*
 Font-weight
 -------------------------*/
$fw-light:300;
$fw-regular:400;
$fw-medium:500;
$fw-semibold:600;
$fw-bold:700;
$fw-black:900;
$fw-black2:800;


/*
 Alignment
 -------------------------*/
$align-center:center;
$align-left:left;