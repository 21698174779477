.seller-verification{
	padding: 27px 0 40px;
	@media all and (min-width: 1300px) and (max-width: 1400px){
		padding: 17px 0 40px;
	}
	@media all and (min-width: 768px){
		padding-bottom: 50px;
	}
	@media all and (min-width: 1200px){
		padding-bottom: 68px;
	}
	.breadcrumb{
		@media all and (min-width: 1200px){
			margin-bottom: 53px;
		}
	}
	.section-header{
		margin-bottom: 20px;
		@media all and (min-width: 768px){
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: center;
		}
		@media all and (min-width: 1200px){
			margin-bottom: 38px;
		}
		.header-left{
			width: 70%;
		}
	}
	h1{
		margin-bottom: 20px;
		line-height: 1.187;
		letter-spacing: -0.2px;
		text-transform: capitalize;
		@media all and (min-width: 768px){
			margin-bottom: 0;
		}
	}
	.btn{
		@media all and (min-width: 768px){
			padding: 7px 80px;
		}
		@media all and (min-width: 992px){
			padding: 4px 101px;
		}
		@media all and (min-width: 1300px) and (max-width: 1400px){
			padding: 4px 98px;
		}
	}
	.information-block{
		@media all and (min-width: 768px){
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		.information-card{
			margin-bottom: 8px;
			padding-bottom: 5px;
			@media all and (min-width: 768px){
				width: calc(50% - 4px);
			}
			@media all and (min-width: 1200px){
				padding: 50px 32px 23px;
				margin-bottom: 8px;
			}
			&.full-width{
				@media all and (min-width: 768px){
					width: 100%;
				}
			}
		}
		h4{
			letter-spacing: -0.2px;
			margin-bottom: 20px;
			color: $gray-5;
			@media all and (min-width: 768px){
				margin-bottom: 27px;
			}
		}
		.info-label{
			margin-bottom: 12px;
			color: $gray-5;
		}
		.seller-detail{
			font-weight: $fw-bold;
			margin-bottom: 29px;
			&.contains-btn{
				display: flex;
				align-items: center;
			}
			.input-wrap{
				width: 100%;
				max-width: 145px;
				margin-right: 5px;
			}
			input{
				background: transparent;
				border: none;
				outline: none;
				width: 100%;
			}
		}
		.input-wrap{
			position: relative;
			input{
				&.padding-fix{
					padding-right: 30px;
				}
			}
		}
		.toggle-password{
			@include absolute(2px, 0);
			z-index: 1;
			button{
				display: block;
				.icon-eye{
					display: block;
					background: url(../../images/icons/icon-eye.svg) no-repeat;
					width: 16px;
					height: 16px;
				}
			}
		}
		.content-wrapper{
			.info-wrapper{
				@media all and (min-width: 992px){
					&:first-child{
						width: 24%;
					}
					&:nth-child(2){
						width: 28%;
					}
					&:nth-child(3){
						width: 24%;
					}
					&:nth-child(4){
						width: 24%;
					}
				}
			}
		}
	}
	.image-wrap{
		max-width: 360px;
		height: 216px;
		margin-bottom: 10px;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		} 
	}
}