// UTIL
@import './utils/_variables.scss';
@import './utils/_mixin.scss';

// BASE
@import './base/_global.scss';

// COMPONENTS
@import './components/_header';
@import './components/_footer';
@import './components/_warning';
@import './components/_entertainment';
@import './components/_slider';
@import './components/_editInfo';
@import './components/_addAddress';
@import './components/_modalImage';
@import './components/_responsePopup';
@import './components/_paymentComplete';
@import './components/_orderError';
@import './components/_deleteReview';
@import './components/_cancelOrder';
@import './components/_addReview';
@import './components/_orderComplete';
@import './components/_addBanner';
@import './components/dataTable';

// Always put _accordion below every component files
@import './components/_accordion';
@import './components/_toast';
@import './components/_customCheckbox';
@import './components/_loader';

// PAGES
@import './pages/_homepage';
@import './pages/_signIn';
@import './pages/_pageNotFound';
@import './pages/_forgotPassword';
@import './pages/_resetPassword';
@import './pages/_createAccount';
@import './pages/_becomeSeller';
@import './pages/_sellerDashboard';
@import './pages/_sellerVerification';
@import './pages/_editSellerVerification';
@import './pages/_optionalInformation';
@import './pages/_sellerInfo';
@import './pages/_editOptionalInfo';
@import './pages/_billingMethod';
@import './pages/_editBillingMethod';
@import './pages/_profile';
@import './pages/_listing';
@import './pages/_editService';
@import './pages/_sellerMessages';
@import './pages/_sellerDialogue';
@import './pages/_addService';
@import './pages/_faq';
@import './pages/_customerService';
@import './pages/_raiseInvoice';
@import './pages/_userDashboard';
@import './pages/_userProfile';
@import './pages/_loginSecurity';
@import './pages/_myAddresses';
@import './pages/_notifications';
@import './pages/_reviews';
@import './pages/_advertiserDashboard';
@import './pages/_cart';
@import './pages/_checkout';
@import './pages/_orders';
@import './pages/_category';
@import './pages/_subCategory';
@import './pages/_sellerAccountComplete';
@import './pages/_sellerLoginComplete';
@import './pages/_sellerOrders';
@import './pages/career';
@import './pages/_termsAndConditions';
@import './pages/_userTransaction';

@import '../../pages/phNumberVerify.scss';


@import './pages/_daysPopup';

@import './components/_rangeSlider';
@import './components/_confirmBox';

@import './pages/_orderDetails';

a,button{pointer-events: none !important;}