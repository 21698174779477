.sign-in {
	padding: 30px 0;

	@media all and (min-width: 1200px) {
		padding: 56px 0 43px;
	}

	.container {
		max-width: 600px;

		@media all and (min-width: 1300px) and (max-width: 1400px) {
			max-width: 609px;
		}
	}

	.card {
		margin-bottom: 16px;

		@media all and (min-width: 768px) {
			padding: 30px 50px;
		}

		@media all and (min-width: 1300px) and (max-width: 1400px) {
			padding: 30px 93px;
		}

		@media all and (min-width: 1440px) {
			padding: 56px 100px 48px;
		}

		&:nth-child(2) {
			@media all and (min-width: 1440px) {
				padding: 37px 167px 35px;
			}
		}
	}

	h2 {
		text-align: center;
	}

	.input-block {
		margin-bottom: 30px;
		position: relative;

		.input-wrap {
			position: relative;
		}

		.error {
			padding: 0;
		}

		.toggle-wrap {
			@include absolute(50%, 12px);
			z-index: 1;
			transform: translate(0, -50%);

			button {
				display: block;

				.icon-eye {
					display: block;
					width: 16px;
					height: 16px;
					background: url(../../images/icons/icon-eye-2.svg) no-repeat;
				}

				&.sign-in-btn,
				&.toggle-btn-wrap {
					background-color: transparent;
				}
			}

			button {
				&.show {
					.icon-eye {
						background: url(../../images/icons/icon-eye-4.svg) no-repeat;
					}
				}
			}
		}
	}

	label {
		color: $gray-3;
		margin-bottom: 5px;

		@media all and (min-width: 1200px) {
			margin-bottom: 11px;
		}
	}

	.form-check-label {
		letter-spacing: 0.2px;
	}

	.forgot-link,
	.signup-link {
		color: $blue;
		text-decoration: underline;
		font-weight: $fw-semibold;
		margin-top: 0;
	}

	.btn-wrap {
		margin: 9px 0 10px;

		@media all and (min-width: 1200px) {
			margin-bottom: 33px;
		}
	}

	.btn {
		@include fontsize(16px);
	}

	.signup-wrap {
		a {
			margin-left: 24px;
		}
	}

	.signin-with {
		text-align: center;
		color: $gray-2;
		font-weight: $fw-semibold;
		margin-bottom: 20px;

		@media all and (min-width: 1200px) {
			margin-bottom: 32px;
		}
	}

	.signin-options {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		a {
			width: 100%;
			border: 1px solid $gray-6;
			@include radius(100vw);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 8px;
			color: $gray-5;
			margin-bottom: 10px;

			@media all and (min-width: 768px) {
				width: calc(50% - 10px);
				margin-bottom: 0;
			}

			.icon-google {
				display: block;
				width: 24px;
				height: 24px;
				background: url(../../images/icons/icon-google.svg) no-repeat;
				margin-right: 10px;

				@media all and (min-width: 1200px) {
					margin-right: 20px;
				}
			}

			.icon-fb {
				display: block;
				width: 24px;
				height: 24px;
				background: url(../../images/icons/icon-fb-2.svg) no-repeat;
				margin-right: 10px;

				@media all and (min-width: 1200px) {
					margin-right: 20px;
				}
			}
		}
	}
}