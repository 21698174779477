.seller-login-complete{
	padding: 30px 0;
	@media all and (min-width: 768px){
		padding: 60px 0;
	}
	.card{
		text-align: center;
		max-width: 505px;
		margin: 0 auto;
		@media all and (min-width: 1200px){
			padding: 56px 52px 48px;
		}
		h2{
			margin-bottom: 30px;
			color: $blue;
		}
		p{
			margin-bottom: 20px;
		}
		.input-wrap{
			position: relative;
			margin-bottom: 25px;
			@media all and (min-width: 768px){
				margin-bottom: 35px;
			}
			.form-control{
				padding-right: 35px;
			}
			&.contains-error{
				.form-control{
					border-color: $red-2;
				}
			}
			.icon-eye{
				display: block;
				@include absolute(50%, 10px);
				background: url(../../images/icons/icon-eye-4.svg) no-repeat;
				width: 16px;
				height: 16px;
				transform: translate(0, -50%);
				z-index: 1;
				cursor: pointer;
				&.open{
					background: url(../../images/icons/icon-eye-2.svg) no-repeat;
				}
			}
			.error{
				padding: 0;
			}
		}
		.btn-wrap{
		}
	}
}