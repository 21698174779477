.cancel-order {
	background: $off-white;
	width: 100%;
	max-width: 512px;
	padding: 15px;
	margin: auto;
	max-height: calc(100vh - 50px);
	overflow: auto;

	@media all and (min-width: 768px) {
		padding: 61px 56px 81px;
		position: relative;

	}

	.close-icon {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 10px;
		right: 10px;
		border-radius: 50%;
		// border: 1px solid black;
		padding: 5px;
		font-weight: 600;
		cursor: pointer;
	}

	h3 {
		margin-bottom: 20px;

		@media all and (min-width: 768px) {
			margin-bottom: 40px;
		}
	}

	.input-wrapper {
		margin-bottom: 2px;
	}

	label {
		margin-bottom: 5px;
		color: $gray-3;

		@media all and (min-width: 768px) {
			margin-bottom: 12px;
		}
	}

	textarea {
		min-height: 137px;
	}

	.has-input-file {
		.img-input-wrapper {
			overflow: hidden;

			.img-input {
				width: 100%;
			}
		}
	}

	.wrap-btn {
		@media all and (min-width: 768px) {
			margin-top: 30px;
		}

		.btn {
			width: 100%;
			@include fontsize(16px);
		}
	}

	.images-wrapper {
		.attached-images {
			margin-right: -10px;
			padding: 10px 0;
			display: flex;
			flex-wrap: wrap;
		}

		.attachment-name.image {
			width: 50px;
			height: 50px;
			margin-right: 10px;
			margin-bottom: 10px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
			}
		}
	}

	&.dispute-order {
		overflow: initial;
		padding: 0;

		&>div {
			min-height: 76vh;
		}

		.content-wrapper {
			padding: 15px;

			@media all and (min-width: 768px) {
				padding: 61px 56px 81px;
			}
		}
	}
}