.order-details {
  background: #e5e5e5;
  padding-bottom: 60px;
  min-height: 60vh;
  .breadcrumb {
    padding-top: 22px;
  }

  .btn-group {
    display: flex;
    flex-direction: column;

    .btn-primary {
      background: #69a1f4;
      border: 1px solid #69a1f4;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
      border-radius: 2px;
      margin-bottom: 10px;
      @include fontsize(16px);
    }

    .btn-secondary {
      background: #f6f6f7;
      border: 1px solid #f46e66;
      box-sizing: border-box;
      color: #f46e66;
      box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
      border-radius: 2px;
      margin-bottom: 10px;
      @include fontsize(16px);
    }
  }

  .user-details {
    background: #ffffff;
    box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;

    h3 {
      @include fontsize(16px);
      color: #505357;
      margin-bottom: 10px;
      font-weight: 600;
    }

    .form-group {
      margin-bottom: 10px;

      .name {
        color: #13171c;
        margin-left: 2px;
        display: inline-block;
        @include fontsize(15px);
        font-weight: 700;
      }

      .highlight {
        color: #009f3a;
      }
    }
    .img-wrap {
      width: 200px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }

  .about {
    background: #ffffff;
    box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;

    .about-img {
      margin-bottom: 10px;
      @media all and (min-width: 768px) {
        width: 208px;
        height: 208px;
      }
      @media all and (min-width: 1200px) {
        width: 360px;
        height: 216px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .about-details {
      padding: 20px 10px;
      position: relative;

      .form-group {
        margin-bottom: 20px;
      }

      label {
        font-weight: 700;
      }

      h3 {
        @include fontsize(16px);
        margin-bottom: 10px;
        font-weight: 600;
      }

      .text {
        margin-left: 2px;
        display: inline-block;
        color: #505357;
      }

      .price {
        color: #13171c;
        font-weight: 600;
      }

      .total-price-wrap {
        white-space: nowrap;
        margin-top: 10px;
        @media all and (min-width: 992px) {
          position: absolute;
          top: 17px;
          right: 17px;
          margin-top: 0;
        }
      }
    }
  }
  .images-wrapper {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .img-wrap {
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
  &.margin-fix {
    .breadcrumb {
      @media all and (min-width: 1200px) {
        margin-bottom: 30px;
      }
    }
  }
}

@media all and (min-width: 768px) {
  .order-details {
    .btn-group {
      flex-direction: row;
      justify-content: flex-end;
      .btn {
        max-width: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        min-height: 40px;
      }
      .btn-primary {
        margin-right: 9px;
        max-width: 154px;
      }
      .btn-secondary {
        max-width: 176px;
      }
    }
  }
}

@media all and (min-width: 992px) {
  .order-details {
    .user-details {
      margin-top: 23px;
      padding: 46px 32px 62px;
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      h3 {
        @include fontsize(20px);
        margin-bottom: 27px;
        width: 100%;
      }

      .content-wrap {
        display: flex;
        width: 75%;
        justify-content: space-between;

        .form-group {
          display: flex;
          flex-direction: column;

          label {
            margin-bottom: 10px;
          }

          .name {
            @include fontsize(16px);
          }
        }
        &.content-table{
          width: 100%;
          margin: 0 -10px;
          .form-group{
            width: calc(20% - 20px);
            margin: 0 10px;
          }
        }
      }
      .images-wrapper {
        width: 100%;
        margin-right: -20px;
        padding-top: 20px;
        .img-wrap {
          margin-right: 20px;
          margin-bottom: 20px;
          width: 24%;
        }
      }
    }

    .about {
      display: flex;
      margin-bottom: 30px;

      .about-img {
        margin-bottom: 0;
      }

      .about-details {
        width: calc(100% - 208px);
        padding: 17px;
        padding-right: 200px;

        h3 {
          @include fontsize(20px);
          max-width: 705px;
          margin-bottom: 25px;
        }

        label {
          margin-bottom: 5px;
        }

        .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 29px;
        }

        .price {
          @include fontsize(24px);
        }
      }
    }

    .orders {
      margin-top: 0;
      margin-bottom: 8px;

      .content-wrap {
        width: 100%;

        .form-group {
          label {
            margin-bottom: 12px;
          }
        }
      }
    }

    .price-details {
      margin-top: 0;
      padding: 50px 32px 62px;

      .content-wrap {
        width: 62.1%;
      }
    }
  }
}

@media all and (min-width: 1200px) {
  .order-details {
    .about {
      .about-details {
        width: calc(100% - 360px);
      }
    }
  }
}
