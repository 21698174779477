.transaction-details {
    padding: 20px 0;

    @media screen and (min-width: 768px) {
        padding: 40px 0;
    }

    h1 {
        margin-bottom: 20px;
    }

    .container {
        >p {
            margin-bottom: 6px;
        }

        .search-input {
            input {
                padding: 6px 14px;
                border-radius: 6px;
                margin-bottom: 14px;
            }
        }

        .data-table-block {
            overflow-x: auto;

            table {
                color: rgba(0, 0, 0, 0.87);
                background-color: rgb(255, 255, 255);
                border: 1px solid #D6D9DE;

                tr {
                    background-color: #fff;
                    border-bottom: 1px solid #D6D9DE;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                th,
                td {
                    padding: 16px;
                    font-size: 16px;
                }

                th {
                    font-weight: 700;
                    background-color: #F1F2F4;
                }

                width: 100%;

                td.successPayment {
                    color: #1d901d;
                }

                td.failedPayment {
                    color: #ff0000;
                }

                td.pendingPayment {
                    color: #c8c813;
                }
                td.unpaidStatus{
                    color: #ff0000;
                }
                td.paidStatus{
                    color: #1d901d;
                }
            }
        }

    }
}