.success-card{
	background: $white;
	box-shadow: 10px 12px 24px 2px $gray-6-transparent;
	@include radius(2px);
	margin: auto;
	padding: 15px;
	width: 100%;
	max-width: 416px;
	position: relative;
	@media all and (min-width: 768px){
		padding: 30px 30px 30px 68px;
	}
	.wrap-icon{
		width: 22px;
		height: 22px;
		margin: 0 auto 20px;
		@media all and (min-width: 768px){
			@include absolute(30px, auto, auto, 32px);
		}
		.icon-success{
			width: 100%;
			height: 100%;
			background: url(../../images/icons/icon-success-2.svg) no-repeat;
			background-size: cover;
			display: block;
		}
	}
	h4{
		margin-bottom: 8px;
	}
	p{
		margin-bottom: 26px;
		color: $gray-5;
	}
	.btn{
		width: 100% !important;
		@media all and (min-width: 768px){
			width: auto !important;
			min-width: initial !important;
		}
		@media all and (min-width: 1200px){
			font-size: 20px !important;
			padding: 3px 17px !important;
		}
	}
}