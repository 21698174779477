.category-block{
	overflow: hidden;
	.container{
		max-width: 1440px;
		padding: 0;
		@media all and (min-width: 768px){
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}
	.categories{
		display: none;
		background: $white;
		box-shadow: 2px 4px 8px $gray-6-transparent;
		@media all and (min-width: 768px){
			display: block;
			width: 250px;
		}
		@media all and (min-width: 992px){
			width: 307px;
		}
		li{
			margin-bottom: 10px;
		}
		a{
			display: block;
			color: $gray-3;
			padding: 7px 10px 7px 16px;
			transition: 0.3s;
			border-right: 3px solid transparent;
			&.active{
				background: $blue-light;
				color: $blue;
				border-color: $blue;
			}
		}
	}
	.particular-category{
		padding: 30px 20px;
		@media all and (min-width: 768px){
			width: calc(100% - 250px);
			padding: 30px;
		}
		@media all and (min-width: 992px){
			width: calc(100% - 307px);
		}
		@media all and (min-width: 1200px){
			padding: 48px 119px;
		}
		h1{
			margin-bottom: 20px;
			@media all and (min-width: 768px){
				margin-bottom: 30px;
			}
			@media all and (min-width: 992px){
				margin-bottom: 42px;
			}
		}
		.services-block{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			@media all and (min-width: 768px){
				justify-content: flex-start;
				margin-right: -20px;
			}
			@media all and (min-width: 1200px){
				margin-right: -28px;
			}
		}
		.service-wrap{
			position: relative;
			width: 100%;
			max-width: 350px;
			height: 279px;
			margin: 0 0 20px;
			@include radius(4px);
			overflow: hidden;
			@media all and (min-width: 768px){
				width: calc(50% - 20px);
				margin-right: 20px;
			}
			@media all and (min-width: 1200px){
				width: calc(33% - 25px);
				margin-right: 28px;
			}
			@media all and (min-width: 1440px){
				margin-bottom: 28px;
			}
			a{
				position: relative;
				display: block;
				height: 100%;
				&:hover{
					.service-info{
						align-items: center;
						background: linear-gradient(47.22deg, #5E86EF 5.72%, #7A9CF3 94.27%);
						opacity: 0.9;
					}
				}
			}
			.img-wrap{
				@include absolute(0, 0, 0, 0);
				width: 100%;
				height: 100%;
				z-index: -1;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					@include radius(4px);
					display: block;
				}
				&:before{
					content: '';
					width: 100%;
					height: 100%;
					@include absolute(0, 0, 0, 0);
					background-image: inherit;
					filter: blur(5px);
					z-index: 2;
				}
				&:after{
					content: '';
					width: 100%;
					height: 70%;
					min-height: 190px;
					background: linear-gradient(0deg, rgba(31, 25, 21, 0.6) 0%, rgba(90, 81, 78, 0) 100%);
					@include absolute(auto, 0, 0, 0);
					z-index: 1;
				}
			}
			.service-info{
				color: $white;
				font-weight: $fw-bold;
				text-align: center;
				padding: 15px 30px;
				transition: 0.3s;
				background: linear-gradient(47.22deg, transparent 5.72%, transparent 94.27%);
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				@media all and (min-width: 768px){
					@include fontsize(18px)
				}
				@media all and (min-width: 992px){
					@include fontsize(20px)
				}
				@media all and (min-width: 1200px){
					@include fontsize(24px)
				}
			}
		}
	}
}