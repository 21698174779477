.email-verification{
    padding: 20px 0;
    text-align: center;
    h2{
        margin-bottom: 30px;
    }
    @media all and (min-width: 768px){
        padding: 50px 0;
        min-height: calc(100vh - 427px);
    }
    @media all and (min-width: 992px){
        padding: 80px 0;
    }
}