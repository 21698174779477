.reviews-block{
	padding: 20px 0 60px;
	.breadcrumb{
		@media all and (min-width: 1200px){
			margin-bottom: 72px;
		}
	}
	h3{
		margin-bottom: 20px;
		@media all and (min-width: 768px){
			margin-bottom: 30px;
		}
		@media all and (min-width: 1200px){
			margin-bottom: 37px;
		}
	}
	.reviews{
		padding: 0;
		.reviews-wrap {
			.review-box{
				margin-bottom: 16px;
			}
		}
	}
	.review-wrap{
		background: $white;
		box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
		border-radius: 4px;
		padding: 15px;
		.img-wrap{
			width: 48px;
			height: 48px;
			margin: 0 auto 20px;
			@include radius(50%);
			overflow: hidden;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
			}
		}
		.name-initial{
			@include fontsize(14px);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 48px;
			height: 48px;
			margin: 0 auto 20px;
			text-transform: capitalize;
			color: $blue;
			background: $blue-light;
			overflow: hidden;
			@include radius(50%);
		}
		.review-stars{
			width: 128px;
			height: 24px;
			margin: 0 auto 20px;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
			}
		}
	}
}