.add-banner{
	background: $white;
	@include radius(4px);
	box-shadow: 2px 4px 8px $blue-transparent-2;
	padding: 15px;
	width: 100%;
	max-width: 700px;
	margin: auto;
	@media all and (min-width: 768px){
		padding: 30px;
	}
	.attachment-name.image{
		margin: 10px 0;
		width: 250px;
		height: 150px;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}
}