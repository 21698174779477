.order-error{
	background: $white;
	border: 1px solid $red-2;
	padding: 15px;
	width: 100%;
	max-width: 360px;
	margin: auto;
	@media all and (min-width: 768px){
		padding: 36px 39px 28px;
	}
	.error-msg{
		color: $red-2;
		font-weight: $fw-bold;
		letter-spacing: -0.8px;
		margin-bottom: 5px;
		@media all and (min-width: 768px){
			@include fontsize(18px);
		}
	}
	h4{
		font-weight: $fw-black;
		letter-spacing: 0.4px;
		@media all and (min-width: 1200px){
			@include fontsize(19px);
		}
	}
	p{
		color: $gray-5;
		position: relative;
		margin-bottom: 46px;
		&:after{
			content: '';
			width: 100%;
			height: 1px;
			background: $gray-11;
			@include absolute(calc(100% + 21px), 0, auto, 0);
		}
	}
	.order-wrapper{
		display: flex;
		flex-wrap: wrap;
		.order-img{
			width: 93px;
			height: 93px;
			img{
				@include radius(8px);
			}
		}
		.order-detail{
			width: calc(100% - 93px);
			padding: 6px 0 0 12px;
			.order-name{
				font-weight: $fw-semibold;
				letter-spacing: 0.9px;
				@media all and (min-width: 768px){
					@include fontsize(18px);
				}
				@media all and (min-width: 1200px){
					@include fontsize(20px);
				}
			}
			.order-provider{
				font-weight: $fw-bold;
				color: $gray-5;
				line-height: 1.5;
			}
		}
	}
	.btn-wrap{
		margin-top: 40px;
		width: 100%;
		box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
		@media all and (min-width: 768px){
			margin-top: 63px;
		}
		a{
			width: 100%;
			position: relative;
			display: block;
			background: $red-2;
			@include radius(2px);
			color: $white;
			transition: 0.3s;
			text-align: center;
			font-weight: $fw-bold;
			padding: 11px 50px;
			&:hover{
				opacity: 0.8;
			}
			.icon-wrap{
				@include absolute(8px, auto, auto, 14px);
				display: block;
				.icon-reload{
					width: 24px;
					height: 24px;
					background: url(../../images/icons/icon-reload.svg) no-repeat;
					display: block;
				}
			}
		}
	}
}