h3 {
    margin-bottom: 16px;

    @media (min-width: 1200px) {
        font-size: 32px;
        margin-bottom: 44px;
    }
}

.aboutus-block .service-info {
    font-size: 20px !important;
    margin-bottom: 15px !important;
}

.aboutus-block {
    padding: 15px;

}

.aboutus-block p {
    padding: 0px;
    margin-bottom: 15px;
    font-size: 14px;
    text-align: center;
}

.aboutus-card-block {
    padding: 0px 0 30px;

    @media (min-width: 1200px) {
        padding: 0px 0 80px;
    }
}

.aboutus-block .img-wrap {
    width: 100% !important;
    height: 50px !important;
    margin: 0 auto 16px !important;
}

.service-wrap.stats-services {
    min-height: 280px !important;
    height: auto !important;
}

@media (min-width: 1440px) {
    .core-services p {
        padding: 0 20%;
    }
}

.aboutus-cards {
    align-items: center;

    @media (min-width: 768px) {
        width: calc(50% - 20px);

    }

    @media (min-width: 1100px) {
        width: calc(33% - 20px);

    }
}

.aboutus-cards-wrap {
    @media (min-width: 768px) {
        display: flex;
        gap: 30px 0px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .aboutus-cards:not(:last-child) {
        margin-bottom: 16px;

        @media (min-width: 768px) {
            margin-bottom: 0;
        }
    }

    .card-img-wrap {
        display: block;
        width: 54px;
        height: 54px;
        // width: 30px;
        // height: 30px;
        border-radius: 62px;
        background: #EAF1FF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-tag-name {
        font-family: Lato;
        // font-size: 32px;
        font-size: 22px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;
        text-transform: uppercase;

    }

    .aboutus-cards p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 300;
        line-height: 16.8px;
        color: #505357;

    }

    @media (min-width: 768px) {
        .aboutus-cards-two {
            flex-direction: row;
            gap: 10px;

        }

        .aboutus-cards {
            border-radius: 12px;
        }
    }

    @media (min-width: 1200px) {
        .aboutus-cards-two {
            gap: 18px;
        }
    }

    @media (min-width: 1200px) {
        .aboutus-cards {
            padding: 22px 18px;

            .card-tag-name {
                margin-bottom: 10px;
            }
        }

        .aboutus-cards-one {
            .card-tag-name {
                font-size: 32px;
            }
        }

        .aboutus-cards-two {
            .card-tag-name {
                font-size: 26px;
            }
        }

        .aboutus-cards {
            border-radius: 12px;
        }
    }
}

@media (min-width: 1440px) {
    .aboutus-cardsp {
        padding: 0 22%;
        margin-bottom: 40px;
    }
}



.aboutus-cards-wrap .aboutus-cards-two .card-tag-name {
    line-height: 23px;
    font-size: 16px;
    @media (min-width: 768px){
        text-align: start;
        }
}
@media (min-width: 1200px){
.aboutus-cards-wrap .aboutus-cards {
    padding: 22px 18px;
}
}