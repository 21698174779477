.review-add{
	background: $gray-12;
	width: 100%;
	max-width: 512px;
	padding: 15px;
	margin: auto;
	@media all and (min-width: 768px){
		padding: 49px 58px 71px;
	}
	h3{
		margin-bottom: 20px;
		@media all and (min-width: 768px){
			margin-bottom: 34px;
		}
	}
	.rating-input{
		display: flex;
		margin-bottom: 24px;
		button{
			background: transparent;
			display: block;
			margin-right: 9px;
			&:last-child{
				margin-right: 0;
			}
			.icon-star{
				background: url(../../images/icons/icon-star-empty.svg) no-repeat;
				width: 32px;
				height: 32px;
				display: block;
			}
			&.selected{
				.icon-star{
					background: url(../../images/icons/icon-star-2.svg) no-repeat;
				}
			}
		}
	}
	.input-wrapper{
		margin-bottom: 25px;
	}
	label{
		margin-bottom: 5px;
		color: $gray-3;
		@media all and (min-width: 768px){
			margin-bottom: 12px;
		}
	}
	textarea{
		min-height: 137px;
	}
	.has-input-file {
		.img-input-wrapper {
			.img-input{
				width: 100%;
			}
		}
	}
	.wrap-btn{
		@media all and (min-width: 768px){
			margin-top: 29px;
		}
		.btn{
			width: 100%;
			@include fontsize(16px);
		}
	}
}