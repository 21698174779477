.edit-information {
	padding: 22px 0 40px;

	@media all and (min-width: 1200px) {
		padding-bottom: 75px;
	}

	@media all and (min-width: 1300px) and (max-width: 1400px) {
		padding: 18px 0 40px;
	}

	h1 {
		letter-spacing: -0.2px;

		@media all and (min-width: 1200px) {
			margin-bottom: 50px;
		}
	}

	.nav-tabs {
		margin-bottom: 31px !important;
	}

	.card {
		padding: 30px 10px;
		margin-bottom: 8px;

		@media all and (min-width: 768px) {
			padding: 30px;
		}

		@media all and (min-width: 1200px) {
			padding: 49px 32px 58px;
		}

		@media all and (min-width: 1300px) and (max-width: 1400px) {
			padding: 49px 30px 58px;
		}

		.manage-time-popup {
			width: 100%;

			h3{
				font-size: 26px;
    			margin-bottom: 14px;
			}
			.input-wrappers {
				display: flex;
				align-items: center;

				.offline-mode {
					display: inline-block;
					margin-left: 20px;
				}
			}

			.input-wrapper {
				position: relative;

				label {
					display: flex;
					align-items: center;

					&:before {
						display: inline-block;
						content: '';
						color: $blue;
						background: url(../../images/icons/icon-star.svg) no-repeat;
						width: 7px;
						height: 7px;
						margin-right: 4px;
					}

				}
			}

			.self-checkbox {
				display: inline-block;
				width: 15px;
				height: 15px;
				margin: 3px 0px 0 10px;
			}

			.radio-btn {
				width: 12px;
				height: 12px;
				border-radius: 50%;
				border-color: #000;
			}

			.user-details {
				display: flex;
				flex-wrap: wrap;
			}

			.input-wrap {
				display: flex;
				align-items: center;
			}

			label {
				display: block;
				margin-bottom: 11px;
			}


			.days {
				display: inline-block;
				min-width: 60px;
			}

			.days-name {
				display: inline-block;
				min-width: 100px;
			}

			.start-time,
			.end-time {
				display: inline-block;
				min-width: 60px;
			}

			.btn-wrapper {
				margin-top: 30px;
				text-align: right;

				.btn-add {
					min-width: 246px;
					padding: 5px 99px;

					&.add-to-cart-btn {
						border: 2px solid #69A1F4;
						box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
						border-radius: 2px;
						font-weight: 700;
						font-size: 1rem;
						padding: 9px 89px;
					}
				}
			}
		}
	}

	label {
		@include fontsize(16px);
		letter-spacing: 0.1px;
		margin-bottom: 5px;
		color: $gray-5;

		@media all and (min-width: 1200px) {
			margin-bottom: 10px;
		}
	}

	.btn-wrap {
		display: flex;
		justify-content: center;
		margin-top: 30px;

		@media all and (min-width: 768px) {
			margin-top: 40px;
			justify-content: flex-end;
		}

		.btn,
		.secondary-btn {
			@media all and (min-width: 992px) {
				padding: 5px 89px 6px;
			}

			@media all and (min-width: 1200px) {
				padding: 4px 89px;
			}

			@media all and (min-width: 1300px) and (max-width: 1400px) {
				padding: 4px 87px;
			}

			&.secondary-cancel-btn {
				padding: 8px 89px;
				font-weight: 700;
			}
		}

		.btn {
			@media all and (min-width: 1200px) {
				padding: 4px 99px;
			}

			@media all and (min-width: 1300px) and (max-width: 1400px) {
				padding: 4px 95px;
			}
		}

		.secondary-btn {
			margin-right: 22px;
		}

		&.btn-group {
			margin-top: 0;
			padding: 0;

			@media all and (min-width: 768px) {
				justify-content: center;
			}
		}
	}
}