.sign-in{
	&.reset-password{
		@media all and (min-width: 1200px){
			padding: 56px 0 106px;
		}
		h2{
			margin-bottom: 14px;
		}
		.reset-info{
			color: $gray-5;
			text-align: center;
			margin-bottom: 20px;
			line-height: 1.5;
			@include fontsize(14px);
			@media all and (min-width: 1200px){
				@include fontsize(16px);
				margin-bottom: 46px;
			}
		}
		.password-hint{
			color: $gray-2;
			margin: 8px 0 20px;
			letter-spacing: 0.2px;
		}
		.login-link{
			color: $gray-3;
			margin-top: 0;
			padding-left: 22px;
			position: relative;
			&:before{
				content: '';
				background: url(../../images/icons/icon-Left.svg) no-repeat;
				width: 12px;
				height: 12px;
				@include absolute(5px, auto, auto, 0);
			}
		}
		.btn-wrap{
			@media all and (min-width: 1200px){
				margin-top: 38px;
			}
		}
	}
}