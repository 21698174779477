.seller-orders {
	padding: 23px 0 40px;
	min-height: 60vh;
	@media all and (min-width: 1200px){
		padding-bottom: 72px;
	}
	.container{
		position: relative;
	}
	.breadcrumb{
		margin-bottom: 59px;
	}
	.nav-tabs {
		.nav-item{
			margin-right: 37px;
			@media all and (min-width: 1300px){
				margin-right:88px;
			}
			@media all and (min-width: 1400px){
				margin-right: 47px;
			}
			&:last-child{
				margin-right: 0;
			}
		}
		.nav-link {
			@include fontsize(18px);
			@media all and (min-width: 768px){
				@include fontsize(20px);
			}
		}
	}
	.btns-wrap{
		@media all and (min-width: 768px){
			display: flex;
			justify-content: flex-end;
		}
		.btn{
			width: 100%;
			margin-bottom: 10px;
			@include fontsize(16px);
			padding: 7px 16px;
			@media all and (min-width: 768px){
				width: auto;
				margin: 0 0 0 8px;
			}
		}
	}
	.btn{
		&.secondary{
			background: $white;
			border-color: $red-2;
			color: $red-2;
			&:hover{
				opacity: 0.8;
			}
		}
	}
	.table-wrap{
		width: 100%;
		box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
		background: $white;
		@include radius(2px);
		overflow: auto;
		border: 1px solid $gray;

	}
	.orders-table{
		min-width: 1058px;
		.table-header{
			display: flex;
			flex-wrap: wrap;
			background: $gray-4;
			padding: 17px 7px 12px;
			border-bottom: 1px solid $gray;
			&>div{
				font-family: $primary-fontfamily;
				font-weight: $fw-bold;
				display: flex;
				position: relative;
				&.contain-search{
					padding-right: 50px;
				}
				&.contain-filter{
					padding-right: 50px;
				}
				.search-wrap{
					@include absolute(-9px, auto, auto, 0);
					z-index: 1;
					width: calc(100% - 50px);
				}
			}
			button{
				padding: 0;
				border: 0;
				outline: none;
				background: transparent;
				display: inline-block;
				&.sort-btn{
					margin-left: 8px;
					.icon-sort{
						display: inline-block;
						background: url(../../images/icons/icon-up-down.svg) no-repeat;
						width: 11px;
						height: 22px;
					}
					&.asc{
						.icon-sort{
							background: url(../../images/icons/icon-up-down-asc.svg) no-repeat;
						}
					}
					&.dsc{
						.icon-sort{
							background: url(../../images/icons/icon-up-down-dsc.svg) no-repeat;
						}
					}
				}
				&.search-btn{
					@include absolute(2px, 23px);
					z-index: 1;
					z-index: 2;
					.icon-search{
						display: inline-block;
						background: url(../../images/icons/icon-search-2.svg) no-repeat;
						width: 16px;
						height: 16px;
					}
				}
				&.filter-btn{
					@include absolute(0, 24px);
					z-index: 1;
					.icon-filter{
						display: inline-block;
						background: url(../../images/icons/icon-filter.svg) no-repeat;
						width: 10px;
						height: 10px;
					}
				}
			}
		}
		.checkbox-col{
			width: 3.3%;
		}
		.id-col{
			width: 19.8%;
			padding-right: 10px;
			@media all and (min-width: 1440px){
				width: 17.8%;
			}
		}
		.date-col{
			width: 15.8%;
			padding-right: 10px;
			@media all and (min-width: 1440px){
				width: 17.8%;
			}
		}
		.status-col{
			width: 15.8%;
			padding-right: 10px;
			font-weight: $fw-bold;
			@media all and (min-width: 1440px){
				width: 17.8%;
			}
			&.in-progress{
				color: $gray-2;
			}
		}
		.name-col{
			padding-right: 10px;
			width: 18%;
			@media all and (min-width: 1440px){
				width: 17.6%;
			}
		}
		.link-col{
			width: 10.9%;
			padding-right: 10px;
			a{
				color: $blue;
				position: relative;
				&:after{
					content: '';
					width: 100%;
					height: 1px;
					background: $blue;
					@include absolute(auto, 0, 0, 0);
					z-index: 1;
				}
			}
		}
		.cta-col{
			width: 16%;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			@media all and (min-width: 1440px){
				width: 14.8%;
			}
			.btn{
				@include fontsize(13px);
				line-height: 1.2;
				padding: 5px 15px;
				margin-right: 8px;
				&.secondary{
					padding: 5px 8px;
					padding: 5px 15px;
					@media all and (min-width: 1440px){
						padding: 5px 15px;
					}
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
		.table-body{
		}
		.table-row{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 16px 7px 14px;
			border-bottom: 1px solid $gray;
			.date-col{
				letter-spacing: 0.4px;
			}
			.form-check{
				.form-check-input{
					margin-top: 4px;
				}
			}
		}
		.form-check{
			padding-left: 0;
			margin-bottom: 0;
			min-height: auto;
			.form-check-input{
				float: none;
				margin: 2px 0 0;
				border-color: $gray;
			}
		}
		.table-footer{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 16px 14px 16px 7px;
			.select-wrap{
				margin-right: 9px;
				.form-control{
					@include fontsize(14px);
					font-family: $third-fontfamily;
					padding: 5px 27px 4px 10px;
					background-position: calc(100% - 10px) 12px !important;
				}
			}
			.goto-wrap{
				display: flex;
				align-items: center;
				label{
					@include fontsize(14px);
					font-family: $third-fontfamily;
					margin: 3px 9px 0 0;
				}
				.form-control{
					width: 50px;
					height: 32px;
				}
			}
		}
	}
	.table-header_cta{
		margin-top: 30px;
		margin-bottom: 30px;
		@media all and (min-width: 768px){
			display: flex;
			justify-content: space-between;
		}

	}
	.search-wrap{
		margin-bottom: 10px;
		@media all and (min-width: 768px){
			margin-bottom: 0;
			max-width: 320px;
		}
	}
	.completed-orders, .canceled-orders, .disputed-orders{
		.rdt_Table{
			.rdt_TableCol, .rdt_TableCell{
				&:nth-child(2){
					min-width: 262px;
				}
				&:nth-child(3){
					@media all and (min-width: 1440px){
						min-width: 259px;
					}
				}
				&:nth-child(4){
					@media all and (min-width: 1440px){
						min-width: 260px;
					}
				}
				&:nth-child(5){
					min-width: 258px;
				}
				&:last-child{
					min-width: initial;
				}
			}
		}
	}
	.statistics-orders{
		padding-top: 16px;
		& > div{
			margin-bottom: 22px;
		}
		.rdt_Table .rdt_TableCol:first-child, .rdt_Table .rdt_TableCell:first-child{
			flex-grow: 1;
		}
	}
}

.images-uploaded{
	display: flex;
	flex-wrap: wrap;
	.images-wrapped{
		width: 42px;
		margin: 5px 4px;
		img{
			display: block;
			width: 100%;
		}
	}
}

.report-wrap-btn{
	margin-top: 28px;
}

.complete-attaches{
	display: flex;
	flex-wrap: wrap;
	margin-top: 12px;
	.doc-link-complete{
		width: 50px;
		margin: 3px;
		filter: saturate(0.5);
		img{
			display: block;
			width: 100%;
		}
	}
}

.search-flex{
	width: 100%;
	max-width: 100% !important;
	@media screen and (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 100% ;
	}
	input{
		max-width: 320px !important;
	}
	>div{
		@media screen and (min-width: 768px) {
			margin-top: 0  !important;
		}
	}
}