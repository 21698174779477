.header{
	background: $white;
	.primary-header{
		padding: 10px 0;
		@media all and (min-width: 992px){
			padding: 15px 0 14px;
		}
	}
	.container{
		max-width: 1320px;
		@media all and (min-width: 768px){
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		@media all and (min-width: 1300px) and (max-width: 1400px){
			.container{
				max-width: 1295px;
			}
		}
	}
	.logo{
		width: 150px;
		margin: 0 auto 15px;
		@media all and (min-width: 768px){
			width: 175px;
			margin: 0;
		}
		@media all and (min-width: 1200px){
			width: 186px;
		}
		@media all and (min-width: 1300px) and (max-width: 1400px){
			width: 145px;
		}
	}
	.search-wrapper{
		margin-bottom: 15px;
		@media all and (min-width: 768px){
			width: 34% ;
			margin-bottom: 0;
		}
		@media all and (min-width: 992px){
			width: 46%;
			max-width: 600px;
		}
		@media all and (min-width: 1300px) and (max-width: 1400px){
			max-width: 569px;
		}
	}
	.input-wrap{
		position: relative;
		@include radius(2px);
		overflow: hidden;
		.btn-wrap{
			@include absolute(0, 0, 0);
			z-index: 1;
			height: 100%;
			.btn{
				width: 100%;
				height: 100%;
				padding: 5px 12px;
				@include radius(0px);
				@media all and (min-width: 1200px){
					padding: 5px 15px;
				}
				.icon-search{	
					display: block;
					background: url(../../images/icons/icon-search.svg) no-repeat;
					width: 14px;
					height: 14px;
					margin: 0 auto;
				}
			}
		}
	}
	.form-control{
		font-family: $secondary-fontfamily;
		letter-spacing: -0.5px;
	}
	.header-right{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.cart-wrap{
		position: relative;
		width: 40px;
		height: 40px;
		margin-right: 15px;
		@media all and (min-width: 992px){
			margin-right: 25px;
		}
		a{
			display: block;
			height: 100%;
		}
		.icon-cart{
			display: block;
			background: url(../../images/icons/icon-cart.svg) no-repeat;
			width: 100%;
			height: 100%;
		}
		.item-counter{
			@include absolute(0, 0, 0);
			width: 20px;
			height: 20px;
			@include radius(50%);
			background: $blue;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			@include fontsize(12px);
			line-height: normal;
			@media all and (min-width: 1200px){
				right: -3px;
			}
		}
	}
	.notification-wrap{
		position: relative;
		width: 32px;
		height: 32px;
		margin-right: 15px;
		@media all and (min-width: 1200px){
			margin-right: 29px;
			margin-left: 4px;
		}
		a{
			display: block;
			width: 100%;
			height: 100%;
		}
		.icon-mail{
			display: block;
			background: url(../../images/icons/icon-mail.svg) no-repeat;
			width: 100%;
			height: 100%;
		}
		.item-counter{
			@include absolute(-5px, -7px);
			width: 20px;
			height: 20px;
			@include radius(50%);
			background: $red-2;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			@include fontsize(12px);
			line-height: normal;
			@media all and (min-width: 1200px){
				right: -7px;
			}
		}
	}
	.profile-dropdown{
		cursor: pointer;
		@include radius(2px);
		position: relative;
		.dropdown-toggle{
			@include fontsize(16px);
			line-height: 1.5;
			border: 0;
			outline: 0;
			color: $gray-3;
			font-weight: $fw-bold;
			border: 1px solid $gray;
			padding: 0;
			padding: 7px 40px 7px 17px;
			background: $white url(../../images/icons/icon-down.svg) no-repeat;
			background-position: calc(100% - 10px) center;
			&:after{
				display: none;
			}
			&:hover, &:focus{
				box-shadow: none;
			}
			@media all and (min-width: 768px){
				padding: 7px 43px 7px 15px;
				background-position: calc(100% - 15px) center;
			}
		}
		.dropdown-menu{
			min-width: 128px;
			top: calc(100% + 4px) !important;
			right: -4px !important;
			background: $white;
			@include radius(2px);
			padding: 4px 0;
			transform: translate3d(0px, 0px, 0px) !important;
			border: 0;
			box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
			a, button{
				padding: 7px 15px;
				color: $gray-3;
				display: block;
				text-align: center;
				width: 100%;
				font-weight: $fw-regular;
				&:hover{
					background: $blue-light;
				}
			}
		}
	}
	.secondary-header{
		background: $gray-3;
		padding: 14px 0 15px;
		position: relative;
		@media all and (min-width: 992px){
			padding: 11px 0 10px;
		}
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.header-left{
			@media all and (min-width: 992px){
				min-width: 160px;
			}
		}
		.menu-btn{
			background: transparent;
			border: 0;
			outline: 0;
			color: $white;
			display: flex;
			align-items: center;
			cursor: pointer;
			.ham-burger{
				width: 21px;
				height: 19px;
				position: relative;
				margin-right: 12px;
				.line{
					display: block;
					height: 2px;
					width: 100%;
					@include radius(1px);
					@include absolute(0, 0);
					background: $white;
					transition: 0.5s;
					&:nth-child(2){
						top: 8px
					}
					&:last-child{
						top: auto;
						bottom: 0;
					}
				}
			}
			&.active{
				.line{
					transform: rotate(45deg);
					&:first-child{
						top: 10px;
					}
					&:nth-child(2){
						opacity: 0;
					}
					&:last-child{
						transform: rotate(-45deg);
						bottom: 7px;
					}
				}
			}
			.text-wrap{
				margin-top: 2px;
				@media all and (min-width: 992px){
					margin-top: 0;
				}
			}
		}
		.nav{
			border-bottom: 3px solid $gray-2;
			@media all and (min-width: 768px){
				padding: 0 10px;
				border: 0;
			}
			ul{
				@media all and (min-width: 768px){
					display: flex;
				}
				li{
					@media all and (min-width: 768px){
						margin: 0 5px;
					}
					@media all and (min-width: 992px){
						margin: 0 10px;
					}
					@media all and (min-width: 1200px){
						margin: 0 20px;
					}
					&:last-child{
						border: 0;
					}
				}
			}
			a{
				color: $gray-2;
				cursor: pointer;
			}
		}
		.navigation{
			@include absolute(100%, 100%, auto, auto);
			width: 100%;
			z-index: 1;
			transition: 0.5s;
			z-index: 2;
			&.active{
				right: 0;
			}
			.container{
				padding: 0 50px 0 0;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			.menu-wrapper{
				width: 100%;
				max-width: 360px;
				background: $white;
				box-shadow: 0px 2px 16px rgba(86, 110, 146, 0.16);
				height: calc(100vh - 222px);
				overflow: auto;
				@media all and (min-width: 768px){
					height: calc(100vh - 124px);
				}
				@media all and (min-width: 1200px){
					height: calc(100vh - 128px);
				}
			}
			.category-list{
				& > li{
					& > a{
						position: relative;
						&:after{
							content: '';
							width: 16px;
							height: 16px;
							@include absolute(50%, 24px);
							z-index: 1;
							background: url(../../images/icons/icon-right.svg) no-repeat;
							transform: translate(0, -50%);
							@media all and (min-width: 768px){
								right: 34px;
							}
						}
					}
				}
			}
			li{
				border-bottom: 1px solid $gray-4;
			}
			a{
				font-weight: $fw-bold;
				color: $gray-3;
				display: block;
				padding: 16px 45px 15px 30px;
				@media all and (min-width: 768px){
					padding: 16px 60px 15px;
				}
			}
			.btn-wrap{
				@include absolute(0, auto, auto, calc(100% + 10px));
				.close-btn{
					background: transparent;
					border: 0;
					outline: 0;
					.icon-cross{
						display: block;
						background: url(../../images/icons/icon-cross.svg) no-repeat;
						width: 31px;
						height: 31px;
					}
				}
			}
		}
		.country-select{
			width: 110px;
			@media all and (min-width: 768px){
				width: 160px;
			}
		}
	}
	&.logged-in{
		.logo{
			@media all and (min-width: 1200px){
				margin-right: 65px;
			}
			@media all and (min-width: 1300px) and (max-width: 1400px){
				margin-right: 5px;
			}
		}
	}
	@media all and (min-width: 1300px) and (max-width: 1400px){
		.container{
			max-width: 1295px;
		}
	}
}

.scroll_searched-items{
	overflow: visible !important;
	width: auto !important;
	height: auto !important;
	>div{
		position: static !important;
		overflow: visible !important;
		margin: 0 !important;
	}
}